import {b64toBlob, isIterableArray, withPermission} from "../../../helpers/utils";
import {withTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Card, Col, Form, Row, Spinner} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import {Field, Formik} from "formik";
import CSRFToken from "../../../helpers/CSRFToken";
import Avatar from "../../../components/common/Avatar";
import FalconDropzone from "../../../components/common/FalconDropzone";
import cloudUpload from "../../../assets/img/icons/cloud-upload.svg";
import FormError from "../../errors/FormError";
import avatarImg from "../../../assets/img/team/avatar.png";
import GoogleMap from "../../../components/map/GoogleMap";
import {Autocomplete} from "@react-google-maps/api";
import IconButton from "../../../components/common/IconButton";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {api} from "../../../utils/api";
import {toast} from "react-toastify";
import paths from "../../../routes/paths";

const SchoolAddEdit = ({t, i18n}) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [map, setMap] = useState(null);
    const [autoComplete, setAutoComplete] = useState(null)
    const [position, setPosition] = useState({
        lat: -33.8688,
        lng: 151.2195
    })
    const [logo, setLogo] = useState([
        {src: avatarImg}
    ]);
    const [formData, setFormData] = useState({
        user: "",
        name: "",
        address: "",
        tagline: "",
        logo: "",
        email: "",
        phone: "",
        website: "",
    })
    const {id} = useParams();
    const navigate = useNavigate()

    const onLoad = (map) => {
        setMap(map);
    };

    const onPlaceChanged = (setFieldValue) => {
        if (map && autoComplete) {
            let markers = [];
            const place = autoComplete.getPlace();
            if (!place.geometry || !place.geometry.location) {
                console.error('Invalid place');
                return;
            }

            const icon = {
                url: place.icon,
                size: new window.google.maps.Size(71, 71),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(17, 34),
                scaledSize: new window.google.maps.Size(25, 25),
            };

            setPosition({
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            });

            markers.push(
                new window.google.maps.Marker({
                    map,
                    icon,
                    title: place.name,
                    position: place.geometry.location,
                }),
            );

            const bounds = new window.google.maps.LatLngBounds();
            if (place.geometry.viewport) {
                // Only geocodes have viewport.
                bounds.union(place.geometry.viewport);
            } else {
                bounds.extend(place.geometry.location);
            }
            map.fitBounds(bounds);

            setFieldValue("address", place)
        }
    };

    const fetchSchool = async () => {
        setLoading(true)
        await api.get(`/school/${id}/`)
            .then(res => setFormData(res?.data))
            .catch(err => {
                if (err?.response?.status === 404) navigate(paths.error404)
            })
        setLoading(false)
    }

    const handleSubmit = async (e, values) => {
        e.preventDefault();
        setLoading(true);
        if (id) {
            await api.patch(`/school/${id}/`, values, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
                .then(res => {
                    toast.success(`${t('title')} ${res?.data?.name} ${t('updateSuccess', {ns: "common"})}`, {theme: "colored"})
                    navigate(paths.schoolList)
                })
                .catch(err => {
                    toast.error(`${t('error', {ns: "common"})}`, {theme: "colored"})
                    setErrors(err?.response?.data)
                })
        } else {
            await api.post("/school/", values, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
                .then(res => {
                    toast.success(`${t('title')} ${res?.data?.name} ${t('createSuccess', {ns: "common"})}`, {theme: "colored"})
                    navigate(paths.schoolList)
                })
                .catch(err => {
                    toast.error(`${t('error', {ns: "common"})}`, {theme: "colored"})
                    setErrors(err?.response?.data)
                })
        }
        setLoading(false)
    }

    useEffect(() => {
        if (id) fetchSchool()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (logo[0].base64) {
            const block = logo[0].base64.split(";");
            // Get the content type of the image
            const contentType = block[0].split(":")[1];
            // get the real base64 content of the file
            const realData = block[1].split(",")[1];

            // Convert it to a blob to upload
            const blob = b64toBlob(realData, contentType);

            // Create a SponsoringFile object
            const file = new File([blob], logo[0].path, {
                type: logo[0].type,
            });
            setFormData({...formData, logo: file})
        }
    }, [formData, logo]);

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Formik initialValues={formData} onSubmit={values => console.log(values)}>
            {({values, setFieldValue}) => (
                <Form>
                    <CSRFToken/>
                    <Row>
                        <Col lg={6}>
                            <Card>
                                <Card.Header as={"h5"}>
                                    {t('basic')}
                                </Card.Header>
                                <Card.Body>
                                    <Form.Group>
                                        <Row className="mb-3">
                                            <Col md="auto">
                                                <Avatar
                                                    size="4xl"
                                                    src={
                                                        isIterableArray(logo)
                                                            ? logo[0]?.base64 || logo[0]?.src
                                                            : ''
                                                    }
                                                />
                                            </Col>
                                            <Col md>
                                                <Field name="avatar">
                                                    {() => (
                                                        <FalconDropzone
                                                            files={logo}
                                                            onChange={files => {
                                                                setLogo(files);
                                                                setFieldValue('logo', files[0]);
                                                            }}
                                                            multiple={false}
                                                            accept="image/*"
                                                            placeholder={
                                                                <>
                                                                    <Flex justifyContent="center">
                                                                        <img
                                                                            src={cloudUpload}
                                                                            alt=""
                                                                            width={25}
                                                                            className="me-2"
                                                                        />
                                                                        <p className="fs-0 mb-0 text-700">
                                                                            Upload your logo
                                                                        </p>
                                                                    </Flex>
                                                                    <p className="mb-0 w-75 mx-auto text-400">
                                                                        Upload a 300x300 jpg image with
                                                                        a maximum size of 400KB
                                                                    </p>
                                                                </>
                                                            }
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors.logo}/>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>{t('fields.name')} <span
                                            className={"text-danger"}>*</span></Form.Label>
                                        <Field name={"name"}>
                                            {({field}) => (
                                                <Form.Control
                                                    type={"text"}
                                                    placeholder={t('fields.name')}
                                                    value={field.value}
                                                    onChange={e => setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                        <FormError error={errors.name}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>{t('fields.tagline')}</Form.Label>
                                        <Field name={"tagline"}>
                                            {({field}) => (
                                                <Form.Control
                                                    type={"text"}
                                                    placeholder={t('fields.tagline')}
                                                    value={field.value}
                                                    onChange={e => setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                        <FormError error={errors.tagline}/>
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6}>
                            <Card>
                                <Card.Header as={"h5"}>
                                    {t('fields.address')} <span className={"text-danger"}>*</span>
                                </Card.Header>
                                <Card.Body>
                                    <Form.Group>
                                        <Field name={"address"}>
                                            {({field}) => (
                                                <GoogleMap
                                                    initialCenter={position}
                                                    onLoad={onLoad}
                                                    mapContainerStyle={{
                                                        width: '100%',
                                                        height: '28vh'
                                                    }}
                                                    extras={
                                                        <Autocomplete
                                                            onLoad={autocomplete => {
                                                                setAutoComplete(autocomplete)
                                                            }}
                                                            onPlaceChanged={() => onPlaceChanged(setFieldValue)}
                                                        >
                                                            <input
                                                                type="text"
                                                                placeholder={`${t('fields.address')}`}
                                                                style={{
                                                                    boxSizing: `border-box`,
                                                                    border: `1px solid transparent`,
                                                                    width: `240px`,
                                                                    height: `32px`,
                                                                    padding: `0 12px`,
                                                                    borderRadius: `3px`,
                                                                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                                                    fontSize: `14px`,
                                                                    outline: `none`,
                                                                    textOverflow: `ellipses`,
                                                                    position: "absolute",
                                                                    left: "50%",
                                                                    marginLeft: "-120px"
                                                                }}
                                                            />
                                                        </Autocomplete>
                                                    }
                                                >
                                                </GoogleMap>
                                            )}
                                        </Field>
                                        <FormError error={errors?.address}/>
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Card className={"mt-3"}>
                                <Card.Header as={"h5"}>
                                    {t('contact')}
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col lg={4}>
                                            <Form.Group>
                                                <Form.Label>{t('fields.email')}</Form.Label>
                                                <Field name={"email"}>
                                                    {({field}) => (
                                                        <Form.Control
                                                            type={"email"}
                                                            value={field.value}
                                                            placeholder={t('fields.email')}
                                                            onChange={e => setFieldValue(field.name, e.target.value)}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors.email}/>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4}>
                                            <Form.Group>
                                                <Form.Label>{t('fields.phone')}</Form.Label>
                                                <Field name={"phone"}>
                                                    {({field}) => (
                                                        <Form.Control
                                                            type={"number"}
                                                            value={field.value}
                                                            placeholder={t('fields.phone')}
                                                            onChange={e => setFieldValue(field.name, e.target.value)}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors.phone}/>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4}>
                                            <Form.Group>
                                                <Form.Label>{t('fields.website')}</Form.Label>
                                                <Field name={"website"}>
                                                    {({field}) => (
                                                        <Form.Control
                                                            type={"url"}
                                                            value={field.value}
                                                            placeholder={t('fields.website')}
                                                            onChange={e => setFieldValue(field.name, e.target.value)}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors.website}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12}>
                            <Card className={"mt-3"}>
                                <Card.Footer>
                                    <Flex justifyContent={'between'} alignItems={"center"} wrap={'wrap'}>
                                        <p className={"text-danger"}>* {t('mandatory', {ns: "common"})}</p>
                                        <IconButton
                                            icon={faSave}
                                            onClick={e => handleSubmit(e, values)}
                                        >
                        <span className="d-none d-sm-inline-block ms-1">
                          {t('save', {ns: "common"})}
                        </span>
                                        </IconButton>
                                    </Flex>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    )
}

export default withPermission(withTranslation(["school", "common"])(SchoolAddEdit), ["school.add_school", "school.change_school"])