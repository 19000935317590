import {Card} from "react-bootstrap";
import IconButton from "../../../components/common/IconButton";
import React from "react";
import {useNavigate} from "react-router-dom";
import Flex from "../../../components/common/Flex";
import {usePermissions} from "../../../providers/PermissionsProvider";
import Swal from "sweetalert2";
import {api} from "../../../utils/api";
import {toast} from "react-toastify";
import paths from "../../../routes/paths";

const WorkspaceHeader = ({data, setLoading}) => {
    const navigate = useNavigate();
    const {hasPermission} = usePermissions();

    const handleDelete = async e => {
        const {isConfirmed} = await Swal.fire({
            title: "Are you sure?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `Yes, Delete it`,
            cancelButtonText: "Cancel"
        });
        if (isConfirmed) {
            e.preventDefault();
            setLoading(true);
            await api.delete(`/collaboration/collaboration/${data?.id}/`).then(() => {
                toast.success(`Collaboration successfully deleted.`, {
                    theme: 'colored'
                });
                navigate(paths.collaborationCenter)
            });
            setLoading(false);
        }
    }
    return (
        <Card>
            <Card.Header>
                <Flex justifyContent={"between"} alignItems={"center"}>
                    <div className={"d-flex align-items-center"}>
                        <IconButton
                            onClick={() => navigate(-1)}
                            variant="falcon-default"
                            size="sm"
                            icon="arrow-left"
                        />
                        <h5 className={"ms-2"}>{data?.name}</h5>
                    </div>
                    <div className={"d-flex align-items-center"}>
                        {hasPermission('collaboration.change_collaboration') &&
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="pencil-alt"
                                transform="shrink-2"
                                iconAlign="middle"
                                className="ms-2 d-none d-sm-block"
                                onClick={() => navigate(paths.collaborationEdit.replace(":id", data?.id))}
                            >
                                <span className="d-none d-md-inline-block ms-1">Edit</span>
                            </IconButton>
                        }
                        {hasPermission('collaboration.delete_collaboration') &&
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="trash-alt"
                                transform="shrink-2"
                                iconAlign="middle"
                                className="ms-2 d-none d-sm-block"
                                onClick={handleDelete}
                            >
                                <span className="d-none d-md-inline-block ms-1">Delete</span>
                            </IconButton>
                        }
                    </div>
                </Flex>
            </Card.Header>
        </Card>
    )
}

export default WorkspaceHeader