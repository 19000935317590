import {Button, Card} from "react-bootstrap";
import FalconCardHeader from "../../../components/common/FalconCardHeader";
import React from "react";
import {Link} from "react-router-dom";
import paths from "../../../routes/paths";

const AccountSecurity = ({user, getUser}) => {
    return (
        <Card className="mb-3">
            <FalconCardHeader title={"Account Security"}/>
            <Card.Body>
                {user?.default_device?.confirmed ? (
                    <>
                        <p>Primary method: {user?.default_device_as_action}</p>
                        {user?.available_phone_methods?.length > 0 && (
                            <>
                                <h6>Backup Phone Numbers</h6>
                                <p>If your primary method is not available, we are able to
                                    send backup tokens to the phone numbers listed below.</p>
                                <ul>
                                    {user?.backup_phones?.map(phone => (
                                        <li key={phone}>{phone}</li>
                                    ))}
                                </ul>
                                <hr/>
                            </>
                        )}
                        <h6>Backup Tokens</h6>
                        <p>If you don't have any device with you, you can access
                            your account using backup tokens. You have {user?.backup_tokens} backup tokens
                            remaining.</p>
                        <Button size={"sm"} variant={"outline-primary"}>Show Codes</Button>
                        <hr/>
                        <h6>Disable Two-Factor Authentication</h6>
                        <p>However we strongly discourage you to do so, you can
                            also disable two-factor authentication for your account.</p>
                        <Button size={"sm"} variant={"outline-danger"}>Disable Two-Factor Authentication</Button>
                    </>
                ) : (
                    <>
                        <h5 className="mb-0">Multi-factor Authentication</h5>
                        <p className="fs--1">
                            Multi-factor authentication is not enabled for your account. Enable multi-factor
                            authentication for
                            enhanced account security.
                        </p>
                        <Button
                            as={Link}
                            to={paths.userMFA}
                            variant="falcon-primary"
                            className="w-100"
                        >
                            Enable multi-factor Authentication
                        </Button>
                    </>
                )}
            </Card.Body>
        </Card>
    )
}
export default AccountSecurity