import React, {Fragment, useEffect, useState} from 'react';
import {Button, Card, Form, ListGroup, Spinner} from 'react-bootstrap';
import Flex from '../../../components/common/Flex';
import CSRFToken from '../../../helpers/CSRFToken';
import VerifiedBadge from '../../../components/common/VerifiedBadge';
import SoftBadge from '../../../components/common/SoftBadge';
import IconButton from '../../../components/common/IconButton';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import useQuery from '../../../hooks/useQuery';
import {api} from '../../../utils/api';
import Swal from 'sweetalert2';
import {toast} from 'react-toastify';
import AdvanceTablePagination from '../../../components/common/advance-table/AdvanceTablePagination';
import FormError from '../../errors/FormError';
import {withPermission} from "../../../helpers/utils";

const PhoneNumbers = () => {
    const [phones, setPhones] = useState([]);
    const [phone, setPhone] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);

    let query = useQuery();

    const getPhones = async q => {
        setLoading(true);
        await api.get(`/account/phone/?${q.toString()}`).then(res => {
            setPhones(res?.data?.results);
            setCount(res?.data?.count)
        });
        setLoading(false);
    };

    const makePrimary = async (e, id) => {
        e.preventDefault();
        setLoading(true);
        await api.post(`/account/phone/${id}/make-primary/`).then(() => {
            toast.success(`Phone number set as primary.`, {
                theme: 'colored'
            });
            getPhones(query);
        });
        setLoading(false);
    };

    const verifyPhone = async (e, id) => {
        e.preventDefault();
        setLoading(true);
        await api.post(`/account/phone/${id}/send/`).then(() => {
            toast.success(`Phone verification sent.`, {
                theme: 'colored'
            });
            getPhones(query);
        });
        setLoading(false);
    };

    const addNewPhone = async e => {
        e.preventDefault();
        setLoading(true);
        await api
            .post(`/account/phone/`, {number: phone})
            .then(() => {
                toast.success(`Phone number successfully added.`, {
                    theme: 'colored'
                });
                getPhones(query);
            })
            .catch(err => {
                toast.error(`An error has occurred.`, {
                    theme: 'colored'
                });
                setErrors(err?.response?.data);
            });
        setLoading(false);
    };

    const handleDelete = async (e, id) => {
        const {isConfirmed} = await Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });
        if (isConfirmed) {
            e.preventDefault();
            setLoading(true);
            await api.delete(`/account/phone/${id}/`).then(() => {
                toast.success(`Phone number successfully deleted.`, {
                    theme: 'colored'
                });
                getPhones(query);
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        getPhones(query);
        // eslint-disable-next-line
    }, []);

    return (
        <Fragment>
            <Card>
                <Card.Header>
                    <Flex justifyContent={"between"} alignItems={"center"} wrap={"wrap"}>
                        <h5>Phone numbers</h5>
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="sync-alt"
                            onClick={(e) => getPhones(query)}
                        />
                    </Flex>
                </Card.Header>
                <Card.Body>
                    {loading ? (
                        <Flex justifyContent="center" className="p-2 mb-2">
                            <Spinner animation={'border'} variant={'primary'}/>
                        </Flex>
                    ) : (
                        <ListGroup>
                            <CSRFToken/>
                            {phones?.map(phone => {
                                return (
                                    <ListGroup.Item>
                                        <Flex justifyContent="between" alignItems="center">
                                            <div>
                                                {phone?.number} {phone?.verified && <VerifiedBadge/>}
                                            </div>
                                            <div>
                                                {phone?.primary ? (
                                                    <SoftBadge pill bg="primary" className="me-2">
                                                        {phone?.primary ? 'Primary' : ''}
                                                    </SoftBadge>
                                                ) : (
                                                    <Button
                                                        className={'btn-sm me-2'}
                                                        onClick={e => makePrimary(e, phone?.id)}
                                                    >
                                                        Make primary
                                                    </Button>
                                                )}

                                                {phone?.verified ? (
                                                    <SoftBadge pill bg="success" className="me-2">
                                                        {phone?.verified ? 'Verified' : ''}
                                                    </SoftBadge>
                                                ) : (
                                                    <Button
                                                        className={'btn-sm me-2'}
                                                        onClick={e => verifyPhone(e, phone?.id)}
                                                    >
                                                        Verify phone number
                                                    </Button>
                                                )}

                                                <IconButton
                                                    icon={faTrash}
                                                    transform={'shrink-3'}
                                                    size={'sm'}
                                                    iconClassName={'text-danger'}
                                                    title={'Remove'}
                                                    className={'bg-transparent border-0'}
                                                    onClick={e => handleDelete(e, phone?.id)}
                                                />
                                            </div>
                                        </Flex>
                                    </ListGroup.Item>
                                );
                            })}
                        </ListGroup>
                    )}
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination
                        count={count}
                        fetch={getPhones}
                        itemsPerPage={10}
                        query={query}
                        length={phones?.length}
                    />
                </Card.Footer>
            </Card>
            <Card className={'mt-3'}>
                <Form>
                    <Card.Header>
                        <h5>Add phone number</h5>
                    </Card.Header>
                    <Card.Body>
                        <Form.Group>
                            <Form.Label>Phone number:</Form.Label>
                            <Form.Control
                                type={'text'}
                                placeholder={'Phone number'}
                                name={'number'}
                                onChange={e => setPhone(e.target.value)}
                            />
                            <FormError error={errors?.number}/>
                        </Form.Group>
                    </Card.Body>
                    <Card.Footer>
                        <Button size={'sm'} variant={'primary'} onClick={addNewPhone}>
                            Add Phone number
                        </Button>
                    </Card.Footer>
                </Form>
            </Card>
        </Fragment>
    );
};

export default withPermission(PhoneNumbers, "accounts.view_accountphonenumber");
