import EventFooter from "./EventFooter";
import {Button, Card, Col, Form, Row, Spinner} from "react-bootstrap";
import EventOtherInfo from "./EventOtherInfo";
import EventSchedule from "./EventSchedule";
import EventTicket from "./EventTicket";
import EventDetails from "./EventDetails";
import EventBanner from "./EventBanner";
import EventHeader from "./EventHeader";
import React, {useEffect, useState} from "react";
import {Formik} from "formik";
import Flex from "../../../components/common/Flex";
import {useNavigate, useParams} from "react-router-dom";
import {api} from "../../../utils/api";
import CSRFToken from "../../../helpers/CSRFToken";
import {withPermission} from "../../../helpers/utils";
import paths from "../../../routes/paths";
import {useAppContext} from "../../../providers/AppProvider";
import RegistrationOptions from "./RegistrationOptions";
import TicketPreviewCard from "../TicketPreviewCard";
import {withTranslation} from "react-i18next";
import EventUpload from "./EventUpload";
import EventCustomField from "./EventCustomField";
import EventSession from "./EventSession";
import EventSpeaker from "./EventSpeaker";
import EventResource from "./EventResource";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBook,
    faCalendarAlt,
    faHandshake,
    faListAlt,
    faTasks,
    faUpload,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import EventCollaborators from "./EventCollaborators";

const EditEvent = ({t, i18n}) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [sections, setSections] = useState({
        sessions: false,
        schedules: false,
        images: false,
        custom_fields: false,
        speakers: false,
        resources: false,
        collaborators: false,
    });
    const [formData, setFormData] = useState({
        activity: "",
        cover: "",
        title: "",
        start_date: "",
        end_date: "",
        registration_deadline: "",
        venue: "",
        address: "",
        delegation: "",
        city: "",
        description: "",
        type: "",
        topic: "",
        tags: [],
        public: "",
        ticket: {
            options: [{name: 'Front desks', price: 0.0, currency: 'TND', checked: true}],
            variable: true,
            type: 'paid',
            title: "",
            color: "#272c97",
            elements: [
                {
                    "key": 0,
                    "field": "component",
                    "align": "left",
                    "text": "",
                    "type": "",
                    "component": "column",
                    "data": "activity_logo"
                },
                {
                    "key": 1,
                    "field": "component",
                    "align": "right",
                    "text": "",
                    "type": "",
                    "component": "column",
                    "data": "event_subtitle"
                },
                {
                    "key": 2,
                    "field": "feed",
                    "align": "",
                    "text": "",
                    "size": 2,
                    "type": "",
                    "component": "",
                    "data": ""
                },
                {
                    "key": 3,
                    "field": "component",
                    "align": "left",
                    "text": "From: ",
                    "type": "",
                    "component": "column",
                    "data": "event_start_date"
                },
                {
                    "key": 4,
                    "field": "component",
                    "align": "right",
                    "text": "To: ",
                    "type": "",
                    "component": "column",
                    "data": "event_end_date"
                },
                {
                    "key": 5,
                    "field": "component",
                    "align": "left",
                    "text": "",
                    "type": "",
                    "component": "row",
                    "color": "#583c3c",
                    "data": "event_title"
                },
                {
                    "key": 6,
                    "field": "component",
                    "align": "left",
                    "text": "",
                    "type": "",
                    "component": "column",
                    "data": "full_name"
                },
                {
                    "key": 7,
                    "field": "component",
                    "align": "right",
                    "text": "",
                    "type": "",
                    "component": "column",
                    "data": "registered_at"
                },
                {
                    "key": 8,
                    "field": "barcode",
                    "align": "",
                    "text": "",
                    "type": "qr_code",
                    "data": "event_id"
                }
            ]
        },
        registration: {
            type: "standard",
            menus: [],
            questions: []
        },
        schedules: [],
        images: [],
        sessions: [],
        custom_fields: [],
        speakers: [],
        resources: [],
        collaborators: []
    });

    const {
        setConfig
    } = useAppContext()

    const navigate = useNavigate();
    const {id} = useParams();

    const getEvent = async () => {
        setLoading(true);
        await api
            .get(`/event/event/${id}/`)
            .then((r) => {
                let updatedResources = r?.data?.eventresource_set?.map(resource => ({
                    name: resource?.name,
                    start_date: resource?.start_date,
                    end_date: resource?.end_date,
                    capacity: resource?.capacity
                }))
                let updatedSpeakers = r?.data?.eventspeaker_set?.map(speaker => ({
                    user: speaker?.user,
                    user_info: speaker?.user_info,
                    start_date: speaker?.start_date,
                    end_date: speaker?.end_date,
                    topic: speaker?.topic,
                    is_lecturer: speaker?.is_lecturer,
                    description: speaker?.description,
                }))
                let updatedSessions = r?.data?.eventsession_set?.map(session => ({
                    name: session.name,
                    description: session.description,
                    start_date: session.start_date,
                    end_date: session.end_date,
                    speakers: session.speakers_info,
                    capacity: session.capacity,
                    location: session.location,
                }))
                let updatedSchedules = r?.data?.schedule_set?.map((schedule) => ({
                    title: schedule.title,
                    start: schedule.start,
                    end: schedule.end,
                    color: schedule.color,
                    description: schedule.description,
                    label: schedule.label,
                }));
                let updatedOptions = r?.data?.ticket_options?.option_set?.map((option) => ({
                    name: option.name,
                    price: option.price,
                    checked: option.checked,
                    currency: option.currency,
                }));
                let updatedFields = r?.data?.eventfield_set?.map(field => ({
                    name: field?.name,
                    value: field?.value,
                    type: field?.type,
                    options: field?.options,
                }))
                let updatedCollaborators = r?.data?.eventcollaborator_set?.map(field => ({
                    activity: field?.activity,
                    role: field?.role,
                }))
                let updatedElements = r?.data?.ticket_options?.ticket_elements?.map((element) => (
                    {
                        "type": element?.type,
                        "field": element?.field,
                        "key": element?.key,
                        "data": element?.data,
                        "text": element?.text,
                        "align": element?.align,
                        "color": element?.color,
                        "size": element?.size,
                    }
                ))
                let updatedTicket = {
                    type: r?.data?.ticket_options?.type || 'paid',
                    variable: r?.data?.ticket_options?.variable || true,
                    title: r?.data?.ticket_options?.title,
                    color: r?.data?.ticket_options?.color,
                    options: updatedOptions,
                    elements: updatedElements
                };
                let updatedRegistration = {
                    type: r?.data?.registration_data?.type,
                    start_date: r?.data?.registration_data?.start_date,
                    end_date: r?.data?.registration_data?.end_date,
                    maximum_registrants: r?.data?.registration_data?.maximum_registrants,
                    url: r?.data?.registration_data?.url,
                    menus: r?.data?.registration_data?.menus_data?.map(menu => ({name: menu?.name})),
                    questions: r?.data?.registration_data?.questions_data?.map(question => ({
                        name: question?.name,
                        type: question?.type,
                        required: question?.required,
                        editable: question?.editable,
                        options: question?.options,
                    }))
                }
                setFormData({
                    ...r?.data,
                    ticket: {...updatedTicket},
                    schedules: updatedSchedules,
                    registration: updatedRegistration,
                    images: r?.data?.images_data?.map(i => ({preview: i?.image_url, data: i?.data})),
                    custom_fields: updatedFields,
                    sessions: updatedSessions,
                    resources: updatedResources,
                    speakers: updatedSpeakers,
                    collaborators: updatedCollaborators
                });
                setSections({
                    sessions: r?.data?.eventsession_set?.length > 0,
                    schedules: r?.data?.schedule_set?.length > 0,
                    images: r?.data?.images_data?.length > 0,
                    custom_fields: r?.data?.eventfield_set?.length > 0,
                    speakers: r?.data?.eventspeaker_set?.length > 0,
                    resources: r?.data?.eventresource_set?.length > 0,
                })
            })
            .catch((error) => {
                if (error?.response?.status === 404) navigate(paths.error404);
                if (error?.response?.status === 500) navigate(paths.error500);
                if (error?.response?.status === 403)
                    setConfig("isAuthenticated", false);
            });
        setLoading(false);
    };

    useEffect(() => {
        getEvent();
        // eslint-disable-next-line
    }, []);

    const handleAddSection = section => {
        setSections(prevSections => ({
            ...prevSections,
            [section]: true,
        }));
    };

    const handleRemove = section => {
        setSections(prevSections => ({
            ...prevSections,
            [section]: false,
        }));
    }

    const onSubmit = (data) => {
        console.log(data);
    };

    return loading ? (
        <>
            <Flex justifyContent="center" alignItems={"center"} className="p-2 mb-2">
                <Spinner animation={"border"} variant={"primary"}/>
            </Flex>
        </>
    ) : (
        <Formik initialValues={formData} onSubmit={(values) => onSubmit(values)}>
            {({values, setFieldValue}) => (
                <Form>
                    <CSRFToken/>
                    <Row className="g-3">
                        <Col xs={12}>
                            <EventHeader
                                values={values}
                                setFormData={setFormData}
                                setLoading={setLoading}
                                setErrors={setErrors}
                                edit={id}
                                t={t}
                            />
                        </Col>
                        <Col xs={12}>
                            <EventBanner
                                values={values}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                t={t}
                            />
                        </Col>
                        <Col lg={8}>
                            <EventDetails
                                values={values}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                t={t}
                                setErrors={setErrors}
                            />
                            <EventTicket
                                values={values}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                t={t}
                            />
                            {sections.sessions && (
                                <EventSession
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    t={t}
                                    handleRemove={handleRemove}
                                />
                            )}
                            {sections.schedules && (
                                <EventSchedule
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    t={t}
                                    handleRemove={handleRemove}
                                />
                            )}
                            {sections.images && (
                                <EventUpload
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    t={t}
                                    onRemove={handleRemove}
                                />
                            )}
                            {sections.custom_fields && (
                                <EventCustomField
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    t={t}
                                    handleRemove={handleRemove}
                                />
                            )}
                            {sections.speakers && (
                                <EventSpeaker
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    t={t}
                                    handleRemove={handleRemove}
                                />
                            )}
                            {sections.resources && (
                                <EventResource
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    t={t}
                                    handleRemove={handleRemove}
                                />
                            )}
                            {sections.collaborators && (
                                <EventCollaborators
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    t={t}
                                    handleRemove={handleRemove}
                                />
                            )}
                            <Card className={"mt-3"}>
                                <Card.Header>
                                    <h5>Add Section</h5>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col lg={6}>
                                            <Button onClick={() => handleAddSection('sessions')}
                                                    className={"mb-2 w-100"} variant={"outline-info"}>
                                                <FontAwesomeIcon icon={faTasks} className={"me-2"}/> Add Sessions and
                                                Activities
                                            </Button>
                                        </Col>
                                        <Col lg={6}>
                                            <Button onClick={() => handleAddSection('schedules')}
                                                    className={"mb-2 w-100"} variant={"outline-success"}>
                                                <FontAwesomeIcon icon={faCalendarAlt} className={"me-2"}/> Add Schedule
                                            </Button>
                                        </Col>
                                        <Col lg={6}>
                                            <Button onClick={() => handleAddSection('images')} className={"mb-2 w-100"}
                                                    variant={"outline-primary"}>
                                                <FontAwesomeIcon icon={faUpload} className={"me-2"}/> Add Upload Photos
                                            </Button>
                                        </Col>
                                        <Col lg={6}>
                                            <Button onClick={() => handleAddSection('custom_fields')}
                                                    className={"mb-2 w-100"}
                                                    variant={"outline-warning"}>
                                                <FontAwesomeIcon icon={faListAlt} className={"me-2"}/> Add Custom Fields
                                            </Button>
                                        </Col>
                                        <Col lg={6}>
                                            <Button onClick={() => handleAddSection('speakers')}
                                                    className={"mb-2 w-100"} variant={"outline-danger"}>
                                                <FontAwesomeIcon icon={faUser} className={"me-2"}/> Add Speakers
                                            </Button>
                                        </Col>
                                        <Col lg={6}>
                                            <Button onClick={() => handleAddSection('resources')}
                                                    className={"mb-2 w-100"} variant={"outline-dark"}>
                                                <FontAwesomeIcon icon={faBook} className={"me-2"}/> Add Resources
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button onClick={() => handleAddSection('collaborators')}
                                                    className={"mb-2 w-100"} variant={"outline-secondary"}>
                                                <FontAwesomeIcon icon={faHandshake} className={"me-2"}/> Add
                                                Collaborators
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <div className={"sticky-sidebar"}>
                                <EventOtherInfo
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    t={t}
                                />
                                <RegistrationOptions
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    t={t}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TicketPreviewCard errors={errors} setFieldValue={setFieldValue} values={values} t={t}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <EventFooter
                                values={values}
                                setFormData={setFormData}
                                setLoading={setLoading}
                                setErrors={setErrors}
                                edit={true}
                                id={id}
                                t={t}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
};

export default withPermission(withTranslation(["events", "common", "collaboration"])(EditEvent), "event.change_event");
