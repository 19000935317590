import React from 'react';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import bgImg from 'assets/img/generic/14.jpg';
import {withTranslation} from "react-i18next";
import TOTPLoginForm from "../TOTPLoginForm";

const TOTPLogin = ({t, i18n}) => {
    return (
        <AuthSplitLayout bgProps={{image: bgImg, position: '50% 20%'}}>
            <h3>Two-Factor Authentication</h3>
            <p className="mb-0 fs--1">
                Your account is protected by two-factor authentication.
            </p>
            <p className="mb-0 fs--1">
                Please enter an authenticator code:
            </p>
            <TOTPLoginForm layout="split" hasLabel t={t} i18n={i18n}/>
        </AuthSplitLayout>
    );
};

export default withTranslation(["authentication", "common"])(TOTPLogin);
