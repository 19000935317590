import {withPermission} from "../../../helpers/utils";
import {withTranslation} from "react-i18next";
import {Card, Col, Form, ListGroup, Row} from "react-bootstrap";
import CSRFToken from "../../../helpers/CSRFToken";
import React from "react";
import app from 'assets/img/icons/app.svg'
import key from 'assets/img/icons/key.svg'
import code from 'assets/img/icons/code.svg'
import AccountMobileApplication from "./AccountMobileApplication";
import RegisterSecurityKey from "./RegisterSecurityKey";
import AccountBackupCodes from "./AccountBackupCodes";

const Mfa = () => {
    return (
        <Card className="theme-wizard mb-5">
            <Form>
                <CSRFToken/>
                <Card.Header as={"h5"}>
                    Multi-Factor Authentication
                </Card.Header>
                <Card.Body>
                    <ListGroup>
                        <ListGroup.Item>
                            <Row>
                                <Col lg={1}>
                                    <img src={app} alt={"Authentication via mobile application"} width={42}
                                         height={42}/>
                                </Col>
                                <Col className={"ms-3"} lg={10}>
                                    <h6>Authenticator App</h6>
                                    <AccountMobileApplication/>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Row>
                                <Col lg={1}>
                                    <img src={key} alt={"Security key authentication"} width={42}
                                         height={42}/>
                                </Col>
                                <Col className={"ms-3"} lg={10}>
                                    <h6>Security key</h6>
                                    <p>Authenticate using a U2F-compatible security key.</p>
                                    <RegisterSecurityKey/>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Row>
                                <Col lg={1}>
                                    <img src={code} alt={"Backup codes"} width={42}
                                         height={42}/>
                                </Col>
                                <Col className={"ms-3"} lg={10}>
                                    <h6>Backup codes</h6>
                                    <AccountBackupCodes/>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    </ListGroup>
                </Card.Body>
                <Card.Footer></Card.Footer>
            </Form>
        </Card>
    )
}
export default withPermission(withTranslation(["common"])(Mfa), "django_otp.view_staticdevice")