import React from "react";
import {Link} from "react-router-dom";
import {Dropdown} from "react-bootstrap";
import Avatar from "components/common/Avatar";
import {toast} from "react-toastify";
import {api} from "../../../utils/api";
import {useAppContext} from "../../../providers/AppProvider";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOutAlt, faUserCircle} from "@fortawesome/free-solid-svg-icons";
import {BsGearFill} from "react-icons/bs";
import paths from "../../../routes/paths";

const ProfileDropdown = () => {
    const {
        config: {avatar, isActivity, group},
        setConfig
    } = useAppContext();

    const {t} = useTranslation("topbar")

    const handleLogout = async () => {
        await api
            .delete("/_allauth/browser/v1/auth/session")
            .catch(() => {
                setConfig("isAuthenticated", false)
                setConfig("permissions", [])
                toast.success("successfully logged out.", {theme: "colored"});
            })
    };
    return (
        <Dropdown navbar={true} as="li">
            <Dropdown.Toggle
                bsPrefix="toggle"
                as={Link}
                to="#!"
                className="pe-0 ps-2 nav-link"
            >
                <Avatar src={avatar}/>
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
                <div className="bg-white rounded-2 py-2 dark__bg-1000">
                    {(isActivity && group?.indexOf(process.env.REACT_APP_ACTIVITY_GROUP_NAME) !== -1) &&
                        <Dropdown.Item as={Link} to={paths.activitySettings}>
                            <BsGearFill/> {t('profile.settings')}
                        </Dropdown.Item>
                    }
                    <Dropdown.Item as={Link} to="/user/profile">
                        <FontAwesomeIcon icon={faUserCircle}/> {t('profile.profile')}
                    </Dropdown.Item>
                    <Dropdown.Divider/>
                    <Dropdown.Item
                        as={Link}
                        onClick={handleLogout}
                        to="/authentication/split/logout"
                    >
                        <FontAwesomeIcon icon={faSignOutAlt}/> {t('profile.logout')}
                    </Dropdown.Item>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default ProfileDropdown;
