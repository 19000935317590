import React, {useState} from "react";
import {Button, Form, Spinner} from "react-bootstrap";
import CSRFToken from "../../helpers/CSRFToken";
import FormError from "../errors/FormError";
import Flex from "../../components/common/Flex";
import {api} from "../../utils/api";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import paths from "../../routes/paths";

const CodeLoginForm = ({t, i18n, hasLabel}) => {
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: ''
    });

    const navigate = useNavigate()
    const query = useQuery()

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = e => {
        setLoading(true)
        e.preventDefault();
        api.post('/_allauth/browser/v1/auth/code/request', formData)
            .catch(err => {
                if (err?.response?.status === 400) {
                    setErrors(err.response.data);
                } else if (err?.response?.status === 401) {
                    toast.success("Login code successfully sent.", {theme: "colored"})
                    navigate(`${paths.splitLoginConfirmCode}?${query.toString()}`)
                }
            })
        setLoading(false)
    }

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Form onSubmit={handleSubmit}>
            <CSRFToken/>
            <Form.Group className="mb-3">
                {hasLabel && <Form.Label>{t('fields.email')}</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? t('fields.email') : ''}
                    value={formData.email}
                    name="email"
                    onChange={handleFieldChange}
                    type="email"
                />
                <FormError error={errors?.email}/>
            </Form.Group>

            <Form.Group>
                <Button type="submit" color="primary" className="mt-3 w-100">
                    Request Code
                </Button>
            </Form.Group>
        </Form>
    )
}

export default CodeLoginForm