import React, {useState} from "react";
import {Button, Card, Col, Form, Row, Spinner} from "react-bootstrap";
import FalconCardHeader from "components/common/FalconCardHeader";
import Avatar from "../../../components/common/Avatar";
import {isIterableArray} from "../../../helpers/utils";
import FalconDropzone from "../../../components/common/FalconDropzone";
import Flex from "../../../components/common/Flex";
import cloudUpload from "../../../assets/img/icons/cloud-upload.svg";
import FormError from "../../errors/FormError";
import avatarImg from "../../../assets/img/team/avatar.png";
import {api} from "../../../utils/api";
import {toast} from "react-toastify";
import CSRFToken from "../../../helpers/CSRFToken";
import DatePicker from "react-datepicker";
import {useNavigate} from "react-router-dom";
import {useAppContext} from "../../../providers/AppProvider";
import paths from "../../../routes/paths";

const ProfileSettings = ({formData, setFormData, user, getUser}) => {
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(
        formData?.birth_date ? new Date(formData?.birth_date) : null
    );
    const [avatars, setAvatar] = useState([
        ...(user.avatar ? user.avatar : []),
        {src: avatarImg},
    ]);

    const navigate = useNavigate();

    const {
        config: {avatar},
        setConfig,
    } = useAppContext();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        await api
            .patch(`/account/user/${user?.id}/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"
                }
            })
            .then((res) => {
                toast.success("Profile successfully updated.", {theme: "colored"});
                getUser();
                setConfig("avatar", res?.data?.url_path);
            })
            .catch((err) => {
                setErrors(err?.response?.data);
            });
        setLoading(false);
    };

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={"border"} variant={"primary"}/>
        </Flex>
    ) : (
        <Card>
            <FalconCardHeader title="Profile Settings"/>
            <Card.Body className="bg-light">
                <Form onSubmit={handleSubmit}>
                    <CSRFToken/>
                    <Form.Group>
                        <Row className="mb-3">
                            <Col md="auto">
                                <Avatar
                                    size="4xl"
                                    src={
                                        isIterableArray(avatar)
                                            ? avatars[0]?.base64 || avatars[0]?.src
                                            : avatar
                                    }
                                />
                            </Col>
                            <Col md>
                                <FalconDropzone
                                    files={avatar}
                                    onChange={(files) => {
                                        setAvatar(files);
                                        setFormData({...formData, avatar: files[0].base64});
                                    }}
                                    multiple={false}
                                    accept="image/*"
                                    placeholder={
                                        <>
                                            <Flex justifyContent="center">
                                                <img
                                                    src={cloudUpload}
                                                    alt=""
                                                    width={25}
                                                    className="me-2"
                                                />
                                                <p className="fs-0 mb-0 text-700">
                                                    Upload your profile picture
                                                </p>
                                            </Flex>
                                            <p className="mb-0 w-75 mx-auto text-400">
                                                Upload a 300x300 jpg image with a maximum size of 400KB
                                            </p>
                                        </>
                                    }
                                />
                                <FormError error={errors.avatar}/>
                            </Col>
                        </Row>
                    </Form.Group>
                    <Row className="mb-3 g-3">
                        <Form.Group as={Col} lg={6} controlId="firstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="First Name"
                                value={formData.first_name}
                                name="first_name"
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group as={Col} lg={6} controlId="lastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Last Name"
                                value={formData.last_name}
                                name="last_name"
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 g-3">
                        <Form.Group
                            as={Col}
                            lg={6}
                            controlId="email"
                            className="align-items-center"
                        >
                            <Flex alignItems="center" justifyContent="between">
                                <Form.Label>Email</Form.Label>
                                <Button
                                    size="sm"
                                    variant="link"
                                    className="p-0"
                                    onClick={() => navigate(paths.userEmails)}
                                >
                                    View All
                                </Button>
                            </Flex>
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                value={formData.email}
                                name="email"
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group as={Col} lg={6} controlId="phone">
                            <Flex justifyContent={"between"} alignItems={"center"}>
                                <Form.Label>Phone</Form.Label>
                                <Button
                                    size="sm"
                                    variant="link"
                                    className="p-0"
                                    onClick={() => navigate(paths.userPhoneNumbers)}
                                >
                                    View All
                                </Button>
                            </Flex>
                            <Form.Control
                                type="text"
                                placeholder="Phone"
                                value={formData.number}
                                name="number"
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="heading">
                                <Form.Label>Heading</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Heading"
                                    value={formData.heading}
                                    name="heading"
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="birth_date">
                                <Form.Label>Birth Date</Form.Label>
                                <Row>
                                    <DatePicker
                                        selected={date}
                                        onChange={(date) => {
                                            setDate(date);
                                            setFormData({
                                                ...formData,
                                                birth_date: new Date(date).toISOString().slice(0, 10),
                                            });
                                        }}
                                        formatWeekDay={(day) => day.slice(0, 3)}
                                        className="form-control"
                                        placeholderText="Select Date"
                                    />
                                </Row>
                                <FormError error={errors?.birth_date}/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group className="mb-3" controlId="intro">
                        <Form.Label>Intro</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={13}
                            placeholder="Intro"
                            value={formData.introduction}
                            name="introduction"
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <div className="text-end">
                        <Button variant="primary" type="submit">
                            Update
                        </Button>
                    </div>
                </Form>
            </Card.Body>
        </Card>
    );
};

export default ProfileSettings;
