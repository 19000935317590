import {Button, Card, Col, Form, Modal, Row, Table} from "react-bootstrap";
import FalconCardHeader from "../../../components/common/FalconCardHeader";
import IconButton from "../../../components/common/IconButton";
import React, {Fragment, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Field, FieldArray} from "formik";
import SimpleBarReact from "simplebar-react";
import useQuery from "../../../hooks/useQuery";
import {getActivities} from "../../activity/actions/Activity";
import {getRoles} from "../../member/actions/Role";
import Select from "react-select";
import CSRFToken from "../../../helpers/CSRFToken";
import FormErrors from "../../errors/FormErrors";
import {getCollaborations} from "../../collaboration-center/actions";
import FormError from "../../errors/FormError";
import TinymceEditor from "../../../components/common/TinymceEditor";
import {Link} from "react-router-dom";
import Flex from "../../../components/common/Flex";

const EventCollaborators = ({t, errors, values, setFieldValue, handleRemove}) => {
    const [activities, setActivities] = useState([])
    const [selectedActivity, setSelectedActivity] = useState([])
    const [page, setPage] = useState(1)
    const [roles, setRoles] = useState([])
    const [selectedRole, setSelectedRole] = useState([])
    const [rolePage, setRolePage] = useState(1)
    const [workspaceModal, setWorkspaceModal] = useState(false)
    const [workspaceType, setWorkspaceType] = useState("existing")
    const [workspaces, setWorkspaces] = useState([])
    const [selectedWorkspace, setSelectedWorkspace] = useState(null)
    const [workspacePage, setWorkspacePage] = useState(1)

    const query = useQuery()

    const fetchActivities = async (q) => {
        await getActivities(q).then(res => setActivities([...activities, ...res?.results]))
    }

    const fetchRoles = async q => {
        await getRoles(q).then(res => setRoles([...roles, ...res?.results]))
    }

    const fetchCollaborations = async q => {
        await getCollaborations(q).then(res => setWorkspaces([...workspaces, ...res?.results]))
    }

    useEffect(() => {
        fetchActivities(query)
        // eslint-disable-next-line
    }, [page])

    useEffect(() => {
        fetchRoles(query)
        // eslint-disable-next-line
    }, [rolePage]);

    useEffect(() => {
        fetchCollaborations(query)
        // eslint-disable-next-line
    }, [workspacePage]);

    const activityOptions = [
        {label: "-------------------------", value: ""},
        ...activities?.map(activity => ({label: activity?.name, value: activity?.id}))
    ]

    const roleOptions = [
        {label: "-------------------------", value: ""},
        ...roles?.map(role => ({label: role?.name, value: role?.id}))
    ]

    const workspaceOptions = [
        {label: "-------------------------", value: ""},
        ...workspaces?.map(workspace => ({label: workspace?.name, value: workspace?.id}))
    ]

    return (
        <Card className="mb-3 mt-3">
            <FalconCardHeader
                light
                title={"Collaborators"}
                titleTag="h5"
                className="py-2"
                endEl={
                    <Flex justifyContent={"between"} alignItems={"center"}>
                        {!values?.collaboration ? (
                            <IconButton
                                variant="falcon-default"
                                icon="plus"
                                size={'sm'}
                                className={"me-2"}
                                iconClassName={'text-primary'}
                                onClick={() => setWorkspaceModal(!workspaceModal)}
                            >
                                <span className={"d-none d-lg-inline-block"}>Add to workspace</span>
                            </IconButton>
                        ) : (
                            <p className={"text-warning me-2"}>A collaboration workspace will be assigned after
                                saving</p>
                        )}
                        <IconButton
                            variant="falcon-default"
                            icon="trash"
                            size={'sm'}
                            iconClassName={'ms-1 text-danger'}
                            onClick={() => handleRemove("collaborators")}
                        >
                            {' '}
                        </IconButton>
                    </Flex>
                }
            />
            <Card.Body>
                <FieldArray name={"collaborators"}
                            render={arrayHelpers => (
                                <SimpleBarReact>
                                    <Table className="bg-white mb-2 dark__bg-1100 mt-3" bordered>
                                        <thead>
                                        <tr className="fs--1">
                                            <th scope="col">{t('fields.other.activity')} <span
                                                className={"text-danger"}>*</span></th>
                                            <th scope="col">{t('fields.role')} <span
                                                className={"text-danger"}>*</span></th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {values?.collaborators?.length > 0 ? (
                                            values?.collaborators?.map((field, index) => (
                                                <tr>
                                                    <td>
                                                        <Form.Group>
                                                            <Field name={`collaborators.${index}.activity`}>
                                                                {({field}) => (
                                                                    <Select
                                                                        options={activityOptions}
                                                                        value={selectedActivity?.find(el => el?.key === index)?.value}
                                                                        placeholder={`${t('select', {ns: 'common'})} ${t('fields.other.activity')}`}
                                                                        onChange={newValue => {
                                                                            setSelectedActivity(prevState => [...prevState, {
                                                                                key: index,
                                                                                value: newValue
                                                                            }])
                                                                            setFieldValue(field.name, newValue.value)
                                                                        }}
                                                                        onInputChange={newValue => {
                                                                            query.set("search", newValue)
                                                                            getActivities(query).then(res => setActivities(res?.results))
                                                                        }}
                                                                        onMenuScrollToBottom={() => {
                                                                            setPage(prevState => prevState + 1)
                                                                        }}
                                                                        menuPortalTarget={document.body} // Ensures dropdown renders outside
                                                                        styles={{
                                                                            menuPortal: base => ({
                                                                                ...base,
                                                                                zIndex: 9999
                                                                            }), // Ensures dropdown stays on top
                                                                            menu: base => ({
                                                                                ...base,
                                                                                zIndex: 9999
                                                                            }) // Ensures dropdown stays on top
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <FormErrors errors={errors?.collaborators}
                                                                        error={"activity"}
                                                                        index={index}/>
                                                        </Form.Group>
                                                    </td>
                                                    <td>
                                                        <Form.Group>
                                                            <Field name={`collaborators.${index}.role`}>
                                                                {({field}) => (
                                                                    <Select
                                                                        options={roleOptions}
                                                                        value={selectedRole?.find(el => el?.key === index)?.value}
                                                                        placeholder={`${t('select', {ns: 'common'})} ${t('fields.role')}`}
                                                                        onChange={newValue => {
                                                                            setSelectedRole(prevState => [...prevState, {
                                                                                key: index,
                                                                                value: newValue
                                                                            }])
                                                                            setFieldValue(field.name, newValue.value)
                                                                        }}
                                                                        onInputChange={newValue => {
                                                                            query.set("search", newValue)
                                                                            getRoles(query).then(res => setRoles(res?.results))
                                                                        }}
                                                                        onMenuScrollToBottom={() => {
                                                                            setRolePage(prevState => prevState + 1)
                                                                        }}
                                                                        menuPortalTarget={document.body} // Ensures dropdown renders outside
                                                                        styles={{
                                                                            menuPortal: base => ({
                                                                                ...base,
                                                                                zIndex: 9999
                                                                            }), // Ensures dropdown stays on top
                                                                            menu: base => ({
                                                                                ...base,
                                                                                zIndex: 9999
                                                                            }) // Ensures dropdown stays on top
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <FormErrors errors={errors?.collaborators} error={"role"}
                                                                        index={index}/>
                                                        </Form.Group>
                                                    </td>
                                                    <td className="text-center align-middle">
                                                        <Button variant="link" size="sm"
                                                                onClick={() => arrayHelpers.remove(index)}>
                                                            <FontAwesomeIcon className="text-danger"
                                                                             icon="times-circle"/>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))) : ""}
                                        </tbody>
                                        <IconButton
                                            onClick={() => arrayHelpers.insert(values?.collaborators?.length, '')}
                                            variant="falcon-default"
                                            size="sm"
                                            icon="plus"
                                            transform="shrink-3"
                                            className={"mt-2"}
                                        >
                                            {t('addItem', {ns: "common"})}
                                        </IconButton>
                                    </Table>
                                </SimpleBarReact>
                            )}
                />
                <Modal show={workspaceModal} onHide={() => setWorkspaceModal(!workspaceModal)} centered size={"lg"}>
                    <Modal.Header closeButton>
                        <h5>Add to a collaboration workspace</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <CSRFToken/>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Check
                                            type={"radio"}
                                            label={"Existing"}
                                            name={"type"}
                                            checked={workspaceType === "existing"}
                                            onChange={e => setWorkspaceType("existing")}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Check
                                            type={"radio"}
                                            label={"New"}
                                            name={"type"}
                                            checked={workspaceType === "new"}
                                            onChange={e => setWorkspaceType("new")}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {workspaceType === "existing" && (
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>{t('fields.collaboration')} <span
                                                className={"text-danger"}>*</span></Form.Label>
                                            <Field name={"collaboration.id"}>
                                                {({field}) => (
                                                    <Select
                                                        options={workspaceOptions}
                                                        value={selectedWorkspace}
                                                        placeholder={`${t('select', {ns: 'common'})} ${t('fields.collaboration')}`}
                                                        onChange={newValue => {
                                                            setSelectedWorkspace(newValue)
                                                            setFieldValue(field.name, newValue.value)
                                                        }}
                                                        onInputChange={newValue => {
                                                            query.set("search", newValue)
                                                            getCollaborations(query).then(res => setWorkspaces(res?.results))
                                                        }}
                                                        onMenuScrollToBottom={() => {
                                                            setWorkspacePage(prevState => prevState + 1)
                                                        }}
                                                        menuPortalTarget={document.body} // Ensures dropdown renders outside
                                                        styles={{
                                                            menuPortal: base => ({
                                                                ...base,
                                                                zIndex: 9999
                                                            }), // Ensures dropdown stays on top
                                                            menu: base => ({
                                                                ...base,
                                                                zIndex: 9999
                                                            }) // Ensures dropdown stays on top
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            )}
                            {workspaceType === "new" && (
                                <Fragment>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>{t('fields.name', {ns: "collaboration"})} <span
                                                    className={"text-danger"}>*</span></Form.Label>
                                                <Field name={"collaboration.name"}>
                                                    {({field}) => (
                                                        <Form.Control
                                                            type={"text"}
                                                            placeholder={t('fields.name', {ns: "collaboration"})}
                                                            value={field.value}
                                                            onChange={e => setFieldValue(field.name, e.target.value)}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors?.collaboration?.name}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>{t('fields.description', {ns: "collaboration"})} <span
                                                    className={"text-danger"}>*</span></Form.Label>
                                                <Field name={"collaboration.description"}>
                                                    {({field}) => (
                                                        <TinymceEditor
                                                            height="13.438rem"
                                                            value={field.value}
                                                            handleChange={newValue => setFieldValue(field.name, newValue)}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors?.collaboration?.description}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Fragment>
                            )}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            as={Link}
                            size="sm"
                            variant="outline-primary"
                            onClick={() => setWorkspaceModal(!workspaceModal)}
                        >
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Card.Body>
            <Card.Footer>

            </Card.Footer>
        </Card>
    )
}

export default EventCollaborators