import envelope from "../../assets/img/icons/spot-illustrations/16.png";
import {Button, Form, Spinner} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import useQuery from "../../hooks/useQuery";
import CSRFToken from "../../helpers/CSRFToken";
import FormErrors2 from "../errors/FormErrors2";
import {api} from "../../utils/api";
import {toast} from "react-toastify";
import {useAppContext} from "../../providers/AppProvider";
import Flex from "../../components/common/Flex";

const VerifyMailContent = ({layout, titleTag: TitleTag, t, i18n}) => {
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const [formData, setFormData] = useState({
        email: ""
    })

    const {setConfig} = useAppContext();
    let query = useQuery()
    const navigate = useNavigate()

    useEffect(() => {
        if (!query.has("next")) query.set("next", "/")
    }, [query]);

    const handleFieldChange = e => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const handleSubmit = async e => {
        setLoading(true)
        await api.get("/_allauth/browser/v1/auth/email/verify")
            .then(async res => {
                setConfig('isAuthenticated', true);
                await api.get("/account/current_user/")
                    .then(res => {
                        setConfig('group', res?.data?.groups_name);
                        setConfig('permissions', res?.data?.permissions);
                        setConfig('isSuperUser', res?.data?.is_superuser);
                        setConfig('isStaff', res?.data?.is_staff);
                        setConfig('isActivity', res?.data?.is_activity);
                        setConfig('isCompany', res?.data?.is_company);
                        setConfig('company', res?.data?.company);
                        setConfig('avatar', res?.data?.url_path);
                        setConfig(
                            'activities_joined',
                            res?.data?.member_in?.map(m => m?.activity)
                        );
                        setConfig('activity', res?.data?.activity);
                        setConfig('settings', JSON.stringify(res?.data?.settings));
                        navigate(query.get("next"));
                        toast.success(`${t('loginSuccess', {ns: 'common'})} ${res?.data?.username}`, {
                            theme: 'colored'
                        });
                    })
            })
            .catch(err => {
                if (err?.response?.status === 400) {
                    setErrors(err.response.data?.errors);
                }
            })
        setLoading(false)
    }

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <>
            <img
                className="d-block mx-auto mb-4"
                src={envelope}
                alt="sent"
                width={100}
            />
            <TitleTag>Request email verification</TitleTag>
            <Form>
                <CSRFToken/>
                <Form.Group>
                    <Form.Control
                        type="text"
                        value={formData.email}
                        placeholder={"Email"}
                        onChange={handleFieldChange}
                        name={"email"}
                    />
                </Form.Group>
                <Form.Group className="mb-4">
                    <Button className="w-100 mt-3" onClick={handleSubmit}>
                        Request verification
                    </Button>
                    <FormErrors2 errors={errors}/>
                </Form.Group>
            </Form>
            <Button
                as={Link}
                color="primary"
                size="sm"
                className="mt-3"
                to={`/authentication/${layout}/login?${query.toString()}`}
            >
                <FontAwesomeIcon
                    icon="chevron-left"
                    transform="shrink-4 down-1"
                    className="me-1"
                />
                {t('verifyMail.loginReturn')}
            </Button>
        </>
    )
}

VerifyMailContent.propTypes = {
    email: PropTypes.string.isRequired,
    layout: PropTypes.string,
    titleTag: PropTypes.string
};

VerifyMailContent.defaultProps = {layout: 'simple', titleTag: 'h4'};

export default VerifyMailContent