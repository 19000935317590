import {withPermission} from "../../../helpers/utils";
import {withTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Card, Col, Form, InputGroup, Row, Spinner} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import {Field, Formik} from "formik";
import CSRFToken from "../../../helpers/CSRFToken";
import {api} from "../../../utils/api";
import useQuery from "../../../hooks/useQuery";
import Select from "react-select";
import {getActivities} from "../../activity/actions/Activity";
import {useAppContext} from "../../../providers/AppProvider";
import IconButton from "../../../components/common/IconButton";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";
import paths from "../../../routes/paths";
import FormError from "../../errors/FormError";

const SiteAddEdit = ({t, i18n}) => {
    const [loading, setLoading] = useState(false);
    const [activities, setActivities] = useState([]);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [page, setPage] = useState(1)
    const [errors, setErrors] = useState({})
    const [formData, setFormData] = useState({
        domain: "",
        name: "",
        type: "",
        activity: "",
    });

    const {
        config: {isActivity}
    } = useAppContext()
    const {id} = useParams();
    const navigate = useNavigate();
    const query = useQuery()

    query.set("page_size", "50")
    query.set("page", page.toString())

    const fetchSite = async () => {
        setLoading(true)
        await api.get(`/site/${id}/`)
            .then(res => {
                setFormData(res?.data)
                setSelectedActivity({label: res?.data?.activity_name, value: res?.data?.activity})
            })
            .catch(err => {
                if (err?.response?.status === 404) navigate(paths.error404)
            })
        setLoading(false)
    }

    const fetchActivities = async (q) => {
        await api.get(`/activity/activity/?${q.toString()}`)
            .then(res => setActivities([...activities, ...res?.data?.results]))
            .catch(err => {
            })
    }

    useEffect(() => {
        if (id) fetchSite()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchActivities(query)
        // eslint-disable-next-line
    }, [page]);

    const activityOptions = [
        {label: "--------------------------", value: ""},
        ...activities?.map(activity => ({label: activity?.name, value: activity?.id}))
    ]

    const handleSubmit = async (e, values) => {
        e.preventDefault()
        setLoading(true)
        if (id) {
            await api.patch(`/site/${id}/`, values)
                .then(res => {
                    toast.success(`${t('title')} ${t('updateSuccess', {ns: "common"})}`, {theme: "colored"})
                    navigate(paths.siteDetail.replace(":id", id));
                })
                .catch(err => {
                    setFormData(values)
                    toast.error(`${t('error', {ns: "common"})}`, {theme: "colored"})
                    setErrors(err?.response?.data)
                })
        } else {
            await api.post("/site/", values)
                .then(res => {
                    toast.success(`${t('title')} ${t('createSuccess', {ns: "common"})}`, {theme: "colored"})
                    navigate(paths.siteDetail.replace(":id", res?.data?.id))
                })
                .catch(err => {
                    setFormData(values)
                    toast.error(`${t('error', {ns: "common"})}`, {theme: "colored"})
                    setErrors(err?.response?.data)
                })
        }
        setLoading(false)
    }

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Formik initialValues={formData} onSubmit={values => console.log(values)}>
            {({values, setFieldValue}) => (
                <Form>
                    <CSRFToken/>
                    <Card>
                        <Card.Body>
                            {!isActivity && (
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>{t('fields.activity')} <span className={"text-danger"}>*</span></Form.Label>
                                            <Field name={"activity"}>
                                                {({field}) => (
                                                    <Select
                                                        options={activityOptions}
                                                        value={selectedActivity}
                                                        onChange={newValue => {
                                                            setSelectedActivity(newValue)
                                                            setFieldValue(field.name, newValue.value)
                                                        }}
                                                        classNamePrefix={"react-select"}
                                                        onMenuScrollToBottom={() => {
                                                            setPage(prevState => prevState + 1)
                                                        }}
                                                        placeholder={`${t('select', {ns: "common"})} ${t('fields.activity')}`}
                                                        onInputChange={newValue => {
                                                            query.set("search", newValue)
                                                            getActivities(query).then(res => setActivities(res?.results))
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </Form.Group>
                                        <FormError error={errors?.activity}/>
                                    </Col>
                                </Row>
                            )}
                            <Form.Group className="mb-3">
                                <Form.Label>{t('fields.type')}</Form.Label>
                                <Row>
                                    <Col>
                                        <Form.Check
                                            type="radio"
                                            label={t("fields.subdomain")}
                                            name="type"
                                            value="subdomain"
                                            checked={values.type === "subdomain"}
                                            onChange={() => setFieldValue("type", "subdomain")}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Check
                                            type="radio"
                                            label={t("fields.custom")}
                                            name="type"
                                            value="custom"
                                            checked={values.type === "custom"}
                                            onChange={() => setFieldValue("type", "custom")}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <FormError error={errors?.type}/>
                        </Card.Body>
                    </Card>
                    <Card className={"mt-3"}>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>{t('fields.domain')} <span
                                            className={"text-danger"}>*</span></Form.Label>
                                        <Field name={"domain"}>
                                            {({field}) => (
                                                values.type === "subdomain" ? (
                                                    <InputGroup>
                                                        <Form.Control
                                                            type={"text"}
                                                            value={field.value}
                                                            placeholder={t('fields.domain')}
                                                            onChange={e => setFieldValue(field.name, e.target.value)}
                                                        />
                                                        <InputGroup.Text>.nexameet.com</InputGroup.Text>
                                                    </InputGroup>
                                                ) : (
                                                    <Form.Control
                                                        type={"text"}
                                                        value={field.value}
                                                        placeholder={t('fields.domain')}
                                                        onChange={e => setFieldValue(field.name, e.target.value)}
                                                    />
                                                )
                                            )}
                                        </Field>
                                        <FormError error={errors?.domain}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>{t('fields.name')} <span
                                            className={"text-danger"}>*</span></Form.Label>
                                        <Field name={"name"}>
                                            {({field}) => (
                                                <Form.Control
                                                    type={"text"}
                                                    value={field.value}
                                                    placeholder={t('fields.name')}
                                                    onChange={e => setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                        <FormError error={errors?.name}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className={"mt-3"}>
                        <Card.Footer>
                            <Flex justifyContent={'between'} alignItems={"center"} wrap={'wrap'}>
                                <p className={"text-danger"}>* {t('mandatory', {ns: "common"})}</p>
                                <IconButton
                                    icon={faSave}
                                    onClick={e => handleSubmit(e, values)}
                                >
                        <span className="d-none d-sm-inline-block ms-1">
                          {t('save', {ns: "common"})}
                        </span>
                                </IconButton>
                            </Flex>
                        </Card.Footer>
                    </Card>
                </Form>
            )}
        </Formik>
    )
}

export default withPermission(withTranslation(["site", "common"])(SiteAddEdit), ["sites.add_site", "sites.change_site"])