import {withPermission} from "../../../helpers/utils";
import {withTranslation} from "react-i18next";
import React, {Fragment, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {api} from "../../../utils/api";
import paths from "../../../routes/paths";
import {Card, Col, Form, Row, Spinner, Table} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import {Field, FieldArray, Formik} from "formik";
import CSRFToken from "../../../helpers/CSRFToken";
import FormError from "../../errors/FormError";
import TinymceEditor from "../../../components/common/TinymceEditor";
import useQuery from "../../../hooks/useQuery";
import Select from "react-select";
import {getActivities} from "../../activity/actions/Activity";
import {useAppContext} from "../../../providers/AppProvider";
import CreatableSelect from "react-select/creatable";
import CustomDateInput from "../../../components/common/CustomDateInput";
import DatePicker from 'react-datepicker';
import FormErrors from "../../errors/FormErrors";
import IconButton from "../../../components/common/IconButton";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";

const WorkspaceAddEdit = ({t, i18n}) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [activities, setActivities] = useState([])
    const [page, setPage] = useState(0)
    const [keywords, setKeywords] = useState([])
    const [selectedKeywords, setSelectedKeywords] = useState([])
    const [selectedActivities, setSelectedActivities] = useState([])
    const [selectedActivity, setSelectedActivity] = useState(null)
    const [date, setDate] = useState(null)
    const [fileTypes, setFileTypes] = useState([])
    const [selectedFileTypes, setSelectedFileTypes] = useState([])
    const [roles, setRoles] = useState([])
    const [selectedRole, setSelectedRole] = useState({})
    const [formData, setFormData] = useState({
        name: "",
        description: "",
        can_chat: false,
        can_schedule: false,
        shared_calendar: false,
        file_options: {
            allow_upload: true,
            size_limit: 0,
            allowed_types: []
        },
        shared_with: [],
        permissions: [],
        agreement: {}
    });

    const {id} = useParams();
    const query = useQuery()
    const navigate = useNavigate();
    const {
        config: {isActivity}
    } = useAppContext()

    const fetchData = async () => {
        setLoading(true);
        await api.get(`/collaboration/collaboration/${id}/`)
            .then(res => {
                setFormData(res?.data)
            })
            .catch(err => {
                if (err?.response?.status === 404) {
                    navigate(paths.error404)
                }
            })
        setLoading(false)
    }

    const fetchKeywords = async () => {
        await api.get("/collaboration/keyword/")
            .then(res => setKeywords(res?.data?.results))
            .catch(() => {
            })
    }

    const fetchTypes = async () => {
        await api.get("/collaboration/filetype/")
            .then(res => setFileTypes(res?.data?.results))
            .catch(() => {
            })
    }

    const fetchActivities = async (q) => {
        setLoading(true)
        await api.get(`/activity/activity/${q.toString()}`)
            .then(res => setActivities([...activities, ...res?.data?.results]))
            .catch(err => {
            })
        setLoading(false)
    }

    const fetchRoles = async (q) => {
        return await api.get(`/member/role/?${q.toString()}`)
            .then(res => setRoles([...roles, ...res?.data?.results]))
            .catch(() => {
            })
    }

    useEffect(() => {
        fetchActivities(query)
        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {
        fetchKeywords()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchTypes()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchRoles(query)
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (id) fetchData();
        // eslint-disable-next-line
    }, [])

    const activitiesOptions = activities?.map(activity => ({label: activity?.name, value: activity?.id}))

    const keywordOptions = keywords?.map(key => ({label: key?.name, value: key?.id}))

    const fileTypeOptions = fileTypes?.map(type => ({label: type?.name, value: type?.id}))

    const roleOptions = [
        {label: "-------------------------", value: ""},
        ...roles?.map(role => ({
            label: role.name,
            value: role.id
        }))
    ]

    const handleSubmit = async (e, values) => {
        e.preventDefault()
        setLoading(true)
        if (id) {

        } else {
            await api.post("/collaboration/collaboration/", values)
                .then(res => {
                    toast.success("Collaboration workspace successfully created.", {theme: "colored"})
                    navigate(paths.collaborationCenter)
                })
                .catch(err => {
                    toast.error("An error has occurred.", {theme: "colored"})
                    setFormData(values)
                    setErrors(err?.response?.data)
                })
        }
        setLoading(false)
    }

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <Formik initialValues={formData} onSubmit={values => console.log(values)}>
                {({values, setFieldValue}) => (
                    <Form>
                        <CSRFToken/>
                        <Row>
                            <Col lg={6}>
                                <Card>
                                    <Card.Header>
                                        <h5>{t('fields.details')}</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Group>
                                            <Form.Label>{t('fields.name')} <span
                                                className={"text-danger"}>*</span></Form.Label>
                                            <Field name={"name"}>
                                                {({field}) => (
                                                    <Form.Control
                                                        type="text"
                                                        placeholder={t('fields.name')}
                                                        value={field.value}
                                                        onChange={e => setFieldValue(field.name, e.target.value)}
                                                    />
                                                )}
                                            </Field>
                                            <FormError error={errors?.name}/>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{t('fields.description')} <span
                                                className={"text-danger"}>*</span></Form.Label>
                                            <Field name={"description"}>
                                                {({field}) => (
                                                    <TinymceEditor
                                                        height="13.438rem"
                                                        value={field.value}
                                                        handleChange={newValue => setFieldValue(field.name, newValue)}
                                                    />
                                                )}
                                            </Field>
                                            <FormError error={errors?.description}/>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{t('fields.keywords')} <span
                                                className={"text-danger"}>*</span></Form.Label>
                                            <Field name={"keywords"}>
                                                {({field}) => (
                                                    <CreatableSelect
                                                        options={keywordOptions}
                                                        value={selectedKeywords}
                                                        onChange={newValue => {
                                                            console.log(newValue)
                                                            newValue?.map(async value => {
                                                                if (value.__isNew__) {
                                                                    await api.post("/collaboration/keyword/", {name: value.value})
                                                                        .then(res => {
                                                                            setSelectedKeywords(prevState => [...prevState, {
                                                                                label: res?.data?.name,
                                                                                value: res?.data?.id
                                                                            }])
                                                                        })
                                                                        .catch(err => {
                                                                        })
                                                                }
                                                            })
                                                            setSelectedKeywords(newValue)
                                                            setFieldValue(field.name, newValue.map(a => a.value))
                                                        }}
                                                        classNamePrefix={"react-select"}
                                                        placeholder={t('fields.keywords')}
                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                    />
                                                )}
                                            </Field>
                                            <FormError error={errors?.keywords}/>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <div className={"sticky-sidebar"}>
                                    <Card className={"mt-3"}>
                                        <Card.Header>
                                            <h5>{t('fields.settings')}</h5>
                                        </Card.Header>
                                        <Card.Body>
                                            <Form.Group>
                                                <Form.Label>{t('fields.visibility')} </Form.Label>
                                                <Field name={"public"}>
                                                    {({field}) => (
                                                        <Form.Switch
                                                            label={field?.value ? t('fields.public') : t('fields.private')}
                                                            checked={field.value}
                                                            onChange={e => setFieldValue(field.name, e.target.checked)}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors?.public}/>
                                            </Form.Group>
                                            <Form.Group>
                                                <Field name={"notification"}>
                                                    {({field}) => (
                                                        <Form.Switch
                                                            label={t('fields.notification')}
                                                            checked={field.value}
                                                            onChange={e => setFieldValue(field.name, e.target.checked)}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors?.notification}/>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>{t('fields.expires')}</Form.Label>
                                                <Field name={"expires"}>
                                                    {({field}) => (
                                                        <Row>
                                                            <DatePicker
                                                                selected={date}
                                                                onChange={date => {
                                                                    setDate(date);
                                                                    setFieldValue(
                                                                        field.name,
                                                                        new Date(date).toISOString()
                                                                    );
                                                                }}
                                                                className="form-control"
                                                                placeholderText={t('expires')}
                                                                showTimeSelect
                                                                fixedHeight
                                                                timeIntervals={5}
                                                                dateFormat="MM/dd/yyyy HH:mm"
                                                                customInput={
                                                                    <CustomDateInput
                                                                        formControlProps={{
                                                                            placeholder: 'MM/dd/yyyy HH:mm',
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </Row>
                                                    )}
                                                </Field>
                                                <FormError error={errors?.expires}/>
                                            </Form.Group>
                                        </Card.Body>
                                    </Card>
                                    <Card className={"mt-3"}>
                                        <Card.Header>
                                            <h5>{t('fields.data_agreement')}</h5>
                                        </Card.Header>
                                        <Card.Body>
                                            <Form.Group>
                                                <Form.Label>{t('fields.data_description')} <span
                                                    className={"text-danger"}>*</span></Form.Label>
                                                <Field name={"agreement.data_description"}>
                                                    {({field}) => (
                                                        <TinymceEditor
                                                            height="13.438rem"
                                                            value={field.value}
                                                            handleChange={newValue => setFieldValue(field.name, newValue)}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors?.agreement?.data_description}/>
                                            </Form.Group>
                                            <Form.Group>
                                                <Field name={"agreement.acknowledgement"}>
                                                    {({field}) => (
                                                        <Form.Switch
                                                            label={t('fields.acknowledgement')}
                                                            checked={field.value}
                                                            handleChange={e => setFieldValue(field.name, e.target.checked)}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors?.agreement?.acknowledgement}/>
                                            </Form.Group>
                                        </Card.Body>
                                    </Card>
                                </div>

                            </Col>
                            <Col lg={6}>
                                <Card>
                                    <Card.Header>
                                        <h5>{t('fields.participants')}</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        {!isActivity &&
                                            <Form.Group>
                                                <Form.Label>{t('fields.created_by')} <span
                                                    className={"text-danger"}>*</span></Form.Label>
                                                <Field name={"created_by"}>
                                                    {({field}) => (
                                                        <Select
                                                            options={activitiesOptions}
                                                            value={selectedActivity}
                                                            onChange={newValue => {
                                                                setSelectedActivity(newValue)
                                                                setFieldValue(field.name, newValue.value)
                                                            }}
                                                            classNamePrefix={"react-select"}
                                                            placeholder={t('fields.created_by')}
                                                            onMenuScrollToBottom={() => {
                                                                setPage(prevState => prevState + 1)
                                                            }}
                                                            onInputChange={newValue => {
                                                                query.set("search", newValue)
                                                                getActivities(query).then(res => setActivities(res?.results))
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors?.created_by}/>
                                            </Form.Group>
                                        }
                                        <Form.Group>
                                            <Form.Label>{t('fields.shared_with')} <span
                                                className={"text-danger"}>*</span></Form.Label>
                                            <Field name={"shared_with"}>
                                                {({field}) => (
                                                    <Select
                                                        options={activitiesOptions}
                                                        value={selectedActivities}
                                                        onChange={newValue => {
                                                            setSelectedActivities(newValue)
                                                            setFieldValue(field.name, newValue.map(a => a.value))
                                                        }}
                                                        classNamePrefix={"react-select"}
                                                        placeholder={t('fields.shared_with')}
                                                        onMenuScrollToBottom={() => {
                                                            setPage(prevState => prevState + 1)
                                                        }}
                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        onInputChange={newValue => {
                                                            query.set("search", newValue)
                                                            getActivities(query).then(res => setActivities(res?.results))
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <FormError error={errors?.shared_with}/>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <div className={"sticky-sidebar"}>
                                    <Card className={"mt-3"}>
                                        <Card.Header>
                                            <h5>{t('fields.permissions')}</h5>
                                        </Card.Header>
                                        <Card.Body>
                                            <Table responsive className="border-bottom" size={'sm'}>
                                                <thead>
                                                <tr>
                                                    <td>{t('fields.unit')}</td>
                                                    <td>{t('fields.role')}</td>
                                                    <td className={'p-0'}></td>
                                                </tr>
                                                </thead>
                                                <FieldArray
                                                    name={'permissions'}
                                                    render={arrayHelpers => (
                                                        <>
                                                            <tbody>
                                                            {values?.permissions && values?.permissions.length > 0
                                                                ? values?.permissions?.map((field, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <Field
                                                                                name={`permissions.${index}.granted_to`}>
                                                                                {({field, form}) => (
                                                                                    <Select
                                                                                        options={selectedActivities}
                                                                                        value={selectedActivities[index]}
                                                                                        onChange={newValue => {
                                                                                            setFieldValue(field.name, newValue.value)
                                                                                        }}
                                                                                        menuPortalTarget={document.body} // Ensures dropdown renders outside
                                                                                        styles={{
                                                                                            menuPortal: base => ({
                                                                                                ...base,
                                                                                                zIndex: 9999
                                                                                            }), // Ensures dropdown stays on top
                                                                                            menu: base => ({
                                                                                                ...base,
                                                                                                zIndex: 9999
                                                                                            }) // Ensures dropdown stays on top
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                            <FormErrors
                                                                                errors={errors?.permissions}
                                                                                error={'granted_to'}
                                                                                index={index}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Field
                                                                                name={`permissions.${index}.role`}>
                                                                                {({field}) => (
                                                                                    <Select
                                                                                        options={roleOptions}
                                                                                        value={selectedRole}
                                                                                        onChange={newValue => {
                                                                                            setSelectedRole(newValue)
                                                                                            setFieldValue(field.name, newValue.value)
                                                                                        }}
                                                                                        placeholder={`${t('select', {ns: 'common'})} ${t('fields.role')}`}
                                                                                        menuPortalTarget={document.body} // Ensures dropdown renders outside
                                                                                        styles={{
                                                                                            menuPortal: base => ({
                                                                                                ...base,
                                                                                                zIndex: 9999
                                                                                            }), // Ensures dropdown stays on top
                                                                                            menu: base => ({
                                                                                                ...base,
                                                                                                zIndex: 9999
                                                                                            }) // Ensures dropdown stays on top
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                            <FormErrors
                                                                                errors={errors?.permissions}
                                                                                error={'role'}
                                                                                index={index}
                                                                            />
                                                                        </td>
                                                                        <td className={'me-0 pe-0'}>
                                                                            <Flex
                                                                                justifyContent={'center'}
                                                                                alignItems={'center'}
                                                                                className={'mt-1'}
                                                                            >
                                                                                <IconButton
                                                                                    variant="falcon-default"
                                                                                    icon="trash"
                                                                                    size={'sm'}
                                                                                    iconClassName={'ms-1 text-danger'}
                                                                                    onClick={() => {
                                                                                        arrayHelpers.remove(index);
                                                                                    }}
                                                                                >
                                                                                    {' '}
                                                                                </IconButton>
                                                                            </Flex>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                                : ''}
                                                            </tbody>
                                                            {values?.permissions?.length < selectedActivities.length &&
                                                                <tfoot>
                                                                <tr>
                                                                    <td>
                                                                        <IconButton
                                                                            variant="falcon-default"
                                                                            size="sm"
                                                                            icon="plus"
                                                                            transform="shrink-3"
                                                                            onClick={() => {
                                                                                arrayHelpers.insert(
                                                                                    values?.permissions.length,
                                                                                    ''
                                                                                );
                                                                            }}
                                                                        >
                                    <span className="d-none d-sm-inline-block ms-1">
                                      Add item
                                    </span>
                                                                        </IconButton>
                                                                    </td>
                                                                </tr>
                                                                </tfoot>
                                                            }
                                                        </>
                                                    )}
                                                />
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                    <Card className={"mt-3"}>
                                        <Card.Header>
                                            <h5>{t('fields.features')}</h5>
                                        </Card.Header>
                                        <Card.Body>
                                            <Form.Group>
                                                <Field name={"can_chat"}>
                                                    {({field}) => (
                                                        <Form.Switch
                                                            label={t('fields.can_chat')}
                                                            checked={field.value}
                                                            onChange={e => setFieldValue(field.name, e.target.checked)}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors?.can_chat}/>
                                            </Form.Group>
                                            <Form.Group>
                                                <Field name={"can_schedule"}>
                                                    {({field}) => (
                                                        <Form.Switch
                                                            label={t('fields.can_schedule')}
                                                            checked={field.value}
                                                            onChange={e => setFieldValue(field.name, e.target.checked)}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors?.can_schedule}/>
                                            </Form.Group>
                                            <Form.Group>
                                                <Field name={"shared_calendar"}>
                                                    {({field}) => (
                                                        <Form.Switch
                                                            label={t('fields.shared_calendar')}
                                                            checked={field.value}
                                                            onChange={e => setFieldValue(field.name, e.target.checked)}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors?.shared_calendar}/>
                                            </Form.Group>
                                        </Card.Body>
                                    </Card>
                                    <Card className={"mt-3"}>
                                        <Card.Header>
                                            <h5>{t('fields.sharing_options')}</h5>
                                        </Card.Header>
                                        <Card.Body>
                                            <Form.Group>
                                                <Field name={"file_options.allow_upload"}>
                                                    {({field}) => (
                                                        <Form.Switch
                                                            label={t('fields.allow_upload')}
                                                            checked={field.value}
                                                            onChange={e => setFieldValue(field.name, e.target.checked)}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors?.file_options?.allow_upload}/>
                                            </Form.Group>
                                            {values?.file_options?.allow_upload && (
                                                <>
                                                    <Form.Group>
                                                        <Form.Label>{t('fields.size_limit')} <span
                                                            className={"text-danger"}>*</span></Form.Label>
                                                        <Field name={"file_options.size_limit"}>
                                                            {({field}) => (
                                                                <Form.Control
                                                                    placeholder={t('fields.size_limit')}
                                                                    type={"number"}
                                                                    value={field.value}
                                                                    onChange={e => setFieldValue(field.name, e.target.value)}
                                                                />
                                                            )}
                                                        </Field>
                                                        <FormError error={errors?.file_options?.size_limit}/>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label>{t('fields.allowed_types')} <span
                                                            className={"text-danger"}>*</span></Form.Label>
                                                        <Field name={"file_options.allowed_types"}>
                                                            {({field}) => (
                                                                <Select
                                                                    options={fileTypeOptions}
                                                                    value={selectedFileTypes}
                                                                    onChange={newValue => {
                                                                        setSelectedFileTypes(newValue)
                                                                        setFieldValue(field.name, newValue.map(v => v.value))
                                                                    }}
                                                                    placeholder={`${t('select', {ns: 'common'})} ${t('fields.allowed_types')}`}
                                                                    classNamePrefix={"react-select"}
                                                                    isMulti
                                                                />
                                                            )}
                                                        </Field>
                                                        <FormError error={errors?.file_options?.allowed_types}/>
                                                    </Form.Group>
                                                </>
                                            )}
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                        <Card className={"mt-3"}>
                            <Card.Footer>
                                <Flex justifyContent={'between'} alignItems={"center"} wrap={'wrap'}>
                                    <p className={"text-danger"}>* {t('mandatory', {ns: 'common'})}</p>
                                    <IconButton
                                        icon={faSave}
                                        onClick={e => handleSubmit(e, values)}
                                    >
                        <span className="d-none d-sm-inline-block ms-1">
                          {t('save', {ns: 'common'})}
                        </span>
                                    </IconButton>
                                </Flex>
                            </Card.Footer>
                        </Card>
                    </Form>
                )}
            </Formik>
        </Fragment>
    )
}

export default withPermission(withTranslation(["collaboration", "common"])(WorkspaceAddEdit), ["collaboration.add_collaboration", "collaboration.change_collaboration"])