import React from 'react';
import VerifyMailKeyContent from "../VerifyMailKeyContent";

const VerifyMail = () => (
    <div className="text-center">
        <VerifyMailKeyContent email="support@nexameet.com"/>
    </div>
);

export default VerifyMail;
