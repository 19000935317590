import React from 'react';
import bgImg from 'assets/img/generic/16.jpg';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import {withTranslation} from "react-i18next";
import VerifyMailContent from "../VerifyMailContent";

const VerifyMail = ({t, i18n}) => {
    return (
        <AuthSplitLayout bgProps={{image: bgImg, position: '50% 30%'}}>
            <div className="text-center">
                <VerifyMailContent email="xyz@abc.com" layout="split" titleTag="h3" t={t} i18n={i18n}/>
            </div>
        </AuthSplitLayout>
    );
};

export default withTranslation(["authentication", "common"])(VerifyMail);
