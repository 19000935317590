import React from 'react';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import bgImg from 'assets/img/generic/14.jpg';
import {withTranslation} from "react-i18next";
import CodeLoginConfirmForm from "../CodeLoginConfirmForm";

const CodeConfirmLogin = ({t, i18n}) => {
    return (
        <AuthSplitLayout bgProps={{image: bgImg, position: '50% 20%'}}>
            <h3>Enter Sign-In Code</h3>
            <p className="mb-0 fs--1">
                The code expires shortly, so please enter it soon.
            </p>
            <CodeLoginConfirmForm layout="split" hasLabel t={t} i18n={i18n}/>
        </AuthSplitLayout>
    );
};

export default withTranslation(["authentication", "common"])(CodeConfirmLogin);
