import {formatDateTime, withPermission} from "../../../helpers/utils";
import {withTranslation} from "react-i18next";
import React, {Fragment, useEffect, useState} from "react";
import {Button, Card, Col, ListGroup, Row, Spinner, Table} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import {useNavigate, useParams} from "react-router-dom";
import {api} from "../../../utils/api";
import paths from "../../../routes/paths";
import WorkspaceHeader from "./WorkspaceHeader";
import Avatar from "../../../components/common/Avatar";
import {BsGearFill} from "react-icons/bs";
import SimpleBarReact from "simplebar-react";
import IconButton from "../../../components/common/IconButton";
import {faCalendarAlt, faFileAlt, faTasks, faVideo} from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import Event from "../../events/Event";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const WorkspaceDetail = ({t, i18n}) => {
    const [loading, setLoading] = useState(false)
    const [collaboration, setCollaboration] = useState({});
    const [nav, setNav] = useState()

    const {id} = useParams();
    const navigate = useNavigate();

    const fetchCollaborationDetails = async () => {
        setLoading(true)
        await api.get(`/collaboration/collaboration/${id}/`)
            .then(res => setCollaboration(res?.data))
            .catch(err => {
                if (err?.response?.status === 404) navigate(paths.error404)
            })
        setLoading(false)
    }

    useEffect(() => {
        fetchCollaborationDetails()
        // eslint-disable-next-line
    }, []);

    let slider;
    useEffect(() => {
        setNav(slider)
    }, [slider]);

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <WorkspaceHeader data={collaboration} setLoading={setLoading}/>
            <Row>
                <Col lg={12}>
                    <Card className={"mt-3"}>
                        <Card.Header as={Flex} justifyContent={"between"} alignItems={"center"}>
                            <h5>Events</h5>
                            <Button
                                size={"sm"}
                                variant={"falcon-default"}
                                transform="shrink-2"
                                iconAlign="middle"
                                onClick={() => navigate(paths.createEvent)}
                            >
                                <FontAwesomeIcon icon={faCalendarAlt}/> <span
                                className={"ms-2 d-none d-lg-inline-block"}>Add Event</span>
                            </Button>
                        </Card.Header>
                        <Card.Body>
                            <Slider
                                slidesToShow={collaboration?.events?.length > 2 ? 3 : 1}
                                asNavFor={nav}
                                swipeToSlide={true}
                                focusOnSelect={true}
                                centerMode={true}
                                arrows={true}
                                centerPadding={"10px"}
                                className="slick-slider-arrow-inner slider variable-width mt-1 mr-n1 position-relative w-md-100 w-lg-75"
                                // navigation={true}
                                // pagination={true}
                                // modules={[Navigation, Pagination]}
                            >
                                {collaboration?.events?.map((event, index) =>
                                    <Event
                                        className='h-sm-50 overflow-visible'
                                        details={event}
                                        key={event.id}
                                        isLast={true}
                                        xs={6}
                                        md={6}
                                        lg={3}
                                        index={index}
                                    />
                                )}
                            </Slider>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={8}>
                    <Card className={"mt-3"}>
                        <Card.Header as={Flex} justifyContent={"between"} alignItems={"center"}>
                            <h5>{collaboration.name}</h5>
                            <Button
                                size={"sm"}
                                variant={"falcon-primary"}
                                transform="shrink-2"
                                iconAlign="middle"
                            >
                                <BsGearFill/> <span className={"ms-2 d-none d-lg-inline-block"}>Change Details</span>
                            </Button>
                        </Card.Header>
                        <Card.Body>
                            <p dangerouslySetInnerHTML={{__html: collaboration.description}}></p>
                            <ListGroup variant="flush">
                                <ListGroup.Item><strong>Keywords:</strong> {collaboration?.keywords_names}
                                </ListGroup.Item>
                                <ListGroup.Item><strong>Created on:</strong> {formatDateTime(collaboration.created_at)}
                                </ListGroup.Item>
                                <ListGroup.Item><strong>Visibility:</strong> {collaboration.public ? 'Public' : 'Private'}
                                </ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card className={"mt-3"}>
                        <Card.Header as={"h5"}>
                            Owner
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <Flex justifyContent={"between"} alignItems={"center"} wrap={"wrap"}>
                                    <Avatar src={collaboration?.owner_logo} size={"4xl"}/>
                                    <p>{collaboration?.owner_name} (Admin)</p>
                                </Flex>
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <Button variant="outline-primary" size={"sm"}>
                                <BsGearFill/> Change Owner
                            </Button>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Card className={"mt-3"}>
                        <Card.Header as={"h5"}>
                            Members
                        </Card.Header>
                        <Card.Body>
                            <SimpleBarReact>
                                <Table responsive bordered>
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Role</th>
                                        <th>Date</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {collaboration?.permissions_data?.map(o => (
                                        <tr>
                                            <td>{o?.granted_to_name}</td>
                                            <td>{o?.role_name}</td>
                                            <td>{formatDateTime(o?.granted_at)}</td>
                                            <td></td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </SimpleBarReact>
                        </Card.Body>
                        <Card.Footer>
                            <IconButton
                                size={"sm"}
                                icon={"user-plus"}
                                variant={"falcon-primary"}
                                transform="shrink-2"
                                iconAlign="middle"
                            >
                                <span className={"ms-2 d-none d-lg-inline-block"}>Invite Member</span>
                            </IconButton>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <Card className={"mt-3"}>
                        <Card.Header as={"h5"}>
                            Recent Activity
                        </Card.Header>
                        <Card.Body>
                            <ListGroup>
                                <ListGroup.Item>Jane uploaded a document</ListGroup.Item>
                                <ListGroup.Item>Alex completed a task</ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card className={"mt-3"}>
                        <Card.Header as={"h5"}>
                            Ongoing Tasks
                        </Card.Header>
                        <Card.Body>
                            <ListGroup>
                                <ListGroup.Item>Task 1 - In Progress</ListGroup.Item>
                                <ListGroup.Item>Task 2 - Completed</ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                        <Card.Footer>
                            <IconButton
                                size={"sm"}
                                icon={faTasks}
                                variant={"falcon-success"}
                                transform="shrink-2"
                                iconAlign="middle"
                            >
                                <span className={"ms-2 d-none d-lg-inline-block"}>Add Task</span>
                            </IconButton>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <Card className={"mt-3"}>
                        <Card.Header as={"h5"}>
                            Shared Files
                        </Card.Header>
                        <Card.Body>
                            <ListGroup>
                                <ListGroup.Item>Project_Plan.pdf</ListGroup.Item>
                                <ListGroup.Item>Meeting_Notes.docx</ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                        <Card.Footer>
                            <IconButton
                                size={"sm"}
                                icon={faFileAlt}
                                variant={"falcon-info"}
                                transform="shrink-2"
                                iconAlign="middle"
                            >
                                <span className={"ms-2 d-none d-lg-inline-block"}>Upload File</span>
                            </IconButton>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card className={"mt-3"}>
                        <Card.Header as={"h5"}>
                            Upcoming Meetings
                        </Card.Header>
                        <Card.Body>
                            <ListGroup>
                                <ListGroup.Item>Team Sync - March 30, 2025</ListGroup.Item>
                                <ListGroup.Item>Client Presentation - April 5, 2025</ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                        <Card.Footer>
                            <IconButton
                                size={"sm"}
                                icon={faVideo}
                                variant={"falcon-warning"}
                                transform="shrink-2"
                                iconAlign="middle"
                            >
                                <span className={"ms-2 d-none d-lg-inline-block"}>Schedule Meeting</span>
                            </IconButton>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default withPermission(withTranslation(["collaboration", "common"])(WorkspaceDetail), "collaboration.view_collaboration")