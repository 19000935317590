import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Link, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Form} from 'react-bootstrap';
import {api} from '../../utils/api';
import FormError from '../../pages/errors/FormError';
import SocialAuthButtons from './SocialAuthButtons';
import Divider from 'components/common/Divider';
import CSRFToken from '../../helpers/CSRFToken';
import useQuery from "../../hooks/useQuery";
import paths from "../../routes/paths";
import FormErrors2 from "../errors/FormErrors2";

const RegistrationForm = ({hasLabel, layout, t, i18n}) => {
    const [errors, setErrors] = useState([]);
    // State
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        password2: '',
        isAccepted: false
    });

    let query = useQuery()
    const navigate = useNavigate()

    useEffect(() => {
        if (!query.has("next")) query.set("next", "/")
    }, [query]);

    // Handler
    const handleSubmit = async e => {
        e.preventDefault();
        await api
            .post('/_allauth/browser/v1/auth/signup', formData)
            .then(res => {
                toast.success(`Verification code sent.`, {
                    theme: 'colored'
                });
                navigate(`${paths.splitVerifyMail}?email=${formData.email}`)
            })
            .catch(err => {
                if (err?.response?.status === 401) {
                    toast.success(`Verification code sent.`, {
                        theme: 'colored'
                    });
                    navigate(`${paths.splitVerifyMail}?email=${formData.email}`)
                }
                setErrors(err?.response?.data?.errors);
            });
    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Form onSubmit={handleSubmit}>
            <CSRFToken/>
            <Form.Group className="mb-3">
                {hasLabel && <Form.Label>{t('fields.username')}</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? t('fields.username') : ''}
                    value={formData.username}
                    name="username"
                    onChange={handleFieldChange}
                    type="text"
                />
                <FormErrors2 errors={errors} param={"username"}/>
            </Form.Group>

            <Form.Group className="mb-3">
                {hasLabel && <Form.Label>{t('fields.email')}</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? t('fields.email') : ''}
                    value={formData.email}
                    name="email"
                    onChange={handleFieldChange}
                    type="email"
                />
                <FormErrors2 errors={errors} param={"email"}/>
            </Form.Group>

            <Form.Group>
                {hasLabel && <Form.Label>{t('fields.password')}</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? t('fields.password') : ''}
                    value={formData.password}
                    name="password"
                    onChange={handleFieldChange}
                    type="password"
                />
                <FormErrors2 errors={errors} param={"password"}/>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Check type="checkbox" id="acceptCheckbox" className="form-check">
                    <Form.Check.Input
                        type="checkbox"
                        name="isAccepted"
                        checked={formData.isAccepted}
                        onChange={e =>
                            setFormData({
                                ...formData,
                                isAccepted: e.target.checked
                            })
                        }
                    />
                    <Form.Check.Label className="form-label">
                        {t('accept')} <Link
                        to={paths.termsConditions}>{t('termsConditions', {ns: 'common'})}</Link> {t('and')}{' '}
                        <Link to={paths.privacyPolicy}>{t('privacyPolicy', {ns: 'common'})}</Link>
                    </Form.Check.Label>
                </Form.Check>
                <FormError error={errors?.isAccepted}/>
            </Form.Group>

            <Form.Group className="mb-4">
                <Button className="w-100" type="submit">
                    {t('register.title')}
                </Button>
                <FormErrors2 errors={errors}/>
            </Form.Group>
            <Divider>{t('or')} {t('register.title')} {t('with')}</Divider>

            <SocialAuthButtons type={'login'}/>
        </Form>
    );
};

RegistrationForm.propTypes = {
    hasLabel: PropTypes.bool
};

export default RegistrationForm;
