import React from "react";
import {createRoot} from "react-dom/client";
import {RouterProvider} from "react-router-dom";
import AppProvider from "providers/AppProvider";
import {router} from "routes";
import "helpers/initFA";
import i18next from "i18next";
import {I18nextProvider, initReactI18next} from "react-i18next";
import common_en from "./translations/en/common.json"
import common_fr from "./translations/fr/common.json"
import common_ar from "./translations/ar/common.json"
import sidebar_en from "./translations/en/common/sidebar.json"
import sidebar_fr from "./translations/fr/common/sidebar.json"
import sidebar_ar from "./translations/ar/common/sidebar.json"
import topbar_en from "./translations/en/common/topbar.json"
import topbar_fr from "./translations/fr/common/topbar.json"
import topbar_ar from "./translations/ar/common/topbar.json"
import activity_en from "./translations/en/activity.json"
import activity_fr from "./translations/fr/activity.json"
import activity_ar from "./translations/ar/activity.json"
import footer_en from "./translations/en/common/footer.json"
import footer_fr from "./translations/fr/common/footer.json"
import footer_ar from "./translations/ar/common/footer.json"
import rule_en from "./translations/en/management/rule.json"
import rule_fr from "./translations/fr/management/rule.json"
import rule_ar from "./translations/ar/management/rule.json"
import contract_en from "./translations/en/management/contract.json"
import contract_fr from "./translations/fr/management/contract.json"
import contract_ar from "./translations/ar/management/contract.json"
import testimonials_en from "./translations/en/management/testimonials.json"
import testimonials_fr from "./translations/fr/management/testimonials.json"
import testimonials_ar from "./translations/ar/management/testimonials.json"
import landing_en from "./translations/en/landing/landing.json";
import landing_fr from "./translations/fr/landing/landing.json";
import landing_ar from "./translations/ar/landing/landing.json";
import authentication_en from "./translations/en/authentication/authentication.json";
import authentication_fr from "./translations/fr/authentication/authentication.json";
import authentication_ar from "./translations/ar/authentication/authentication.json";
import users_en from "./translations/en/accounts/users.json";
import users_fr from "./translations/fr/accounts/users.json";
import users_ar from "./translations/ar/accounts/users.json";
import groups_en from "./translations/en/accounts/groups.json";
import groups_fr from "./translations/fr/accounts/groups.json";
import groups_ar from "./translations/ar/accounts/groups.json";
import members_en from "./translations/en/members/members.json";
import members_fr from "./translations/fr/members/members.json";
import members_ar from "./translations/ar/members/members.json";
import roles_en from "./translations/en/members/roles.json";
import roles_fr from "./translations/fr/members/roles.json";
import roles_ar from "./translations/ar/members/roles.json";
import address_en from "./translations/en/address/address.json";
import address_fr from "./translations/fr/address/address.json";
import address_ar from "./translations/ar/address/address.json";
import events_en from "./translations/en/events/events.json";
import events_fr from "./translations/fr/events/events.json";
import events_ar from "./translations/ar/events/events.json";
import posts_en from "./translations/en/blog/posts.json";
import posts_fr from "./translations/fr/blog/posts.json";
import posts_ar from "./translations/ar/blog/posts.json";
import periodics_en from "./translations/en/management/periodics.json";
import periodics_fr from "./translations/fr/management/periodics.json";
import periodics_ar from "./translations/ar/management/periodics.json";
import newsletter_en from "./translations/en/management/newsletter.json";
import newsletter_fr from "./translations/fr/management/newsletter.json";
import newsletter_ar from "./translations/ar/management/newsletter.json";
import bug_en from "./translations/en/bug/bug.json";
import bug_fr from "./translations/fr/bug/bug.json";
import bug_ar from "./translations/ar/bug/bug.json";
import tutorials_en from "./translations/en/tutorials/tutorials.json";
import tutorials_fr from "./translations/fr/tutorials/tutorials.json";
import tutorials_ar from "./translations/ar/tutorials/tutorials.json";
import dashboard_en from "./translations/en/dashboard/dashboard.json";
import dashboard_fr from "./translations/fr/dashboard/dashboard.json";
import dashboard_ar from "./translations/ar/dashboard/dashboard.json";
import enotice from "./translations/enotice.json";
import billing from "./translations/billing.json";
import collaboration from "./translations/collaboration.json";
import venue from "./translations/venue.json";
import site from "./translations/site.json";
import school from "./translations/school.json";
import {PermissionsProvider} from "./providers/PermissionsProvider";
import {getItemFromStore} from "./helpers/utils";
import App from "./App";

const container = document.getElementById("main");
const root = createRoot(container, <App/>);

i18next
    .use(initReactI18next)
    .init({
        interpolation: {escapeValue: false},  // React already does escaping
        lng: getItemFromStore("lang", "en"),
        resources: {
            en: {
                common: common_en,
                sidebar: sidebar_en,
                topbar: topbar_en,
                footer: footer_en,
                activity: activity_en,
                rule: rule_en,
                contract: contract_en,
                users: users_en,
                landing: landing_en,
                authentication: authentication_en,
                groups: groups_en,
                members: members_en,
                roles: roles_en,
                address: address_en,
                events: events_en,
                posts: posts_en,
                testimonials: testimonials_en,
                periodics: periodics_en,
                bug: bug_en,
                tutorials: tutorials_en,
                newsletter: newsletter_en,
                dashboard: dashboard_en,
                enotice: enotice.en,
                billing: billing.en,
                collaboration: collaboration.en,
                venue: venue.en,
                site: site.en,
                school: school.en,
            },
            fr: {
                common: common_fr,
                sidebar: sidebar_fr,
                topbar: topbar_fr,
                footer: footer_fr,
                activity: activity_fr,
                rule: rule_fr,
                contract: contract_fr,
                users: users_fr,
                landing: landing_fr,
                authentication: authentication_fr,
                groups: groups_fr,
                members: members_fr,
                roles: roles_fr,
                address: address_fr,
                events: events_fr,
                posts: posts_fr,
                testimonials: testimonials_fr,
                periodics: periodics_fr,
                bug: bug_fr,
                tutorials: tutorials_fr,
                newsletter: newsletter_fr,
                dashboard: dashboard_fr,
                enotice: enotice.fr,
                billing: billing.fr,
                collaboration: collaboration.fr,
                venue: venue.fr,
                site: site.fr,
                school: school.fr,
            },
            ar: {
                common: common_ar,
                sidebar: sidebar_ar,
                topbar: topbar_ar,
                footer: footer_ar,
                activity: activity_ar,
                rule: rule_ar,
                contract: contract_ar,
                users: users_ar,
                landing: landing_ar,
                authentication: authentication_ar,
                groups: groups_ar,
                members: members_ar,
                roles: roles_ar,
                address: address_ar,
                events: events_ar,
                posts: posts_ar,
                testimonials: testimonials_ar,
                periodics: periodics_ar,
                bug: bug_ar,
                tutorials: tutorials_ar,
                newsletter: newsletter_ar,
                dashboard: dashboard_ar,
                enotice: enotice.ar,
                billing: billing.ar,
                collaboration: collaboration.ar,
                venue: venue.ar,
                site: site.ar,
                school: school.ar,
            },
        }
    });

root.render(
    <React.StrictMode>
        <AppProvider>
            <PermissionsProvider>
                <I18nextProvider i18n={i18next}>
                    <RouterProvider router={router}/>
                </I18nextProvider>
            </PermissionsProvider>
        </AppProvider>
    </React.StrictMode>
);
