import {formatDateTime, withPermission} from "../../helpers/utils";
import {withTranslation} from "react-i18next";
import React, {useCallback, useEffect, useState} from "react";
import useQuery from "../../hooks/useQuery";
import {useAppContext} from "../../providers/AppProvider";
import {usePermissions} from "../../providers/PermissionsProvider";
import {Link, useNavigate} from "react-router-dom";
import {api} from "../../utils/api";
import paths from "../../routes/paths";
import {debounce} from "lodash";
import Flex from "../../components/common/Flex";
import Avatar from "../../components/common/Avatar";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import CardDropdown from "../../components/common/CardDropdown";
import {Card, Dropdown, Spinner} from "react-bootstrap";
import AdvanceTableWrapper from "../../components/common/advance-table/AdvanceTableWrapper";
import BaseTableHeader from "../BaseTableHeader";
import AdvanceTable from "../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../components/common/advance-table/AdvanceTablePagination";

const Schools = ({t, i18n}) => {
    const [schools, setSchools] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortingField, setSortingField] = useState('created_at'); // Set the initial sorting field
    const [sortingDirection, setSortingDirection] = useState('desc');
    const [count, setCount] = useState(0);
    const [length, setLength] = useState(0);
    const query = useQuery();

    const {
        config: {isAuthenticated},
        setConfig
    } = useAppContext();

    const {hasPermission} = usePermissions()

    const navigate = useNavigate();

    const fetchSchools = async q => {
        setLoading(true);
        const sortingParam = `${
            sortingDirection === 'desc' ? '-' : ''
        }${sortingField}`;

        query.set('ordering', sortingParam);
        await api.get(`/school/?${q.toString()}`)
            .then(res => {
                setSchools(res?.data?.results);
                setCount(res?.data?.count);
            })
            .catch((error) => {
                if (error?.response?.status === 404) navigate(paths.error404);
                if (error?.response?.status === 500) navigate(paths.error500);
                if (error?.response?.status === 403)
                    setConfig("isAuthenticated", !isAuthenticated);
            });
        setLength(schools.length);
        setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedLoadItems = useCallback(debounce(fetchSchools, 600), []);

    useEffect(() => {
        fetchSchools(query);
        setLength(schools.length);
        navigate(`?${query.toString()}`);
        // eslint-disable-next-line
    }, [sortingField, sortingDirection]);

    const columns = [
        {
            accessor: 'name',
            Header: t('fields.name'),
            canSort: true,
            isSorted: true,
            sortingDirection: sortingField === 'name' && sortingDirection,
            headerProps: {className: 'pe-1'},
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const {id, name, logo} = rowData.row.original;
                return (
                    <>
                        <Link to={paths.schoolChange.replace(":id", id)}>
                            <Flex alignItems="center">
                                {logo ? (
                                    <Avatar
                                        src={logo}
                                        rounded={'circle'}
                                        size="4xl"
                                        className="me-2"
                                    />
                                ) : (
                                    <Avatar
                                        size="4xl"
                                        name={name}
                                        rounded={'circle'}
                                        className="me-2"
                                    />
                                )}
                                <div className="flex-1">
                                    <h6
                                        className="mb-0 fs-0"
                                        style={{
                                            width: '200px',
                                            wordWrap: 'break-word',
                                            wordBreak: 'break-all',
                                            whiteSpace: 'pre-wrap'
                                        }}
                                    >
                                        {name}
                                    </h6>
                                </div>
                            </Flex>
                        </Link>
                    </>
                );
            }
        },
        {
            headerProps: {className: 'pe-1'},
            cellProps: {
                className: 'py-2'
            },
            accessor: 'phone',
            Header: t('fields.phone'),
            canSort: true,
            sortingDirection: sortingField === 'phone' && sortingDirection,
            isSorted: false,
        },
        {
            headerProps: {className: 'pe-1'},
            cellProps: {
                className: 'py-2'
            },
            accessor: 'email',
            Header: t('fields.email'),
            canSort: true,
            sortingDirection: sortingField === 'email' && sortingDirection,
            isSorted: false,
        },
        {
            headerProps: {className: 'pe-1'},
            cellProps: {
                className: 'py-2'
            },
            accessor: 'website',
            Header: t('fields.website'),
            canSort: true,
            sortingDirection: sortingField === 'website' && sortingDirection,
            isSorted: false,
        },
        {
            headerProps: {className: 'pe-1'},
            cellProps: {
                className: 'py-2'
            },
            accessor: 'created_at',
            Header: t('fields.created_at'),
            canSort: true,
            sortingDirection: sortingField === 'created_at' && sortingDirection,
            isSorted: false,
            Cell: rowData => {
                const {created_at} = rowData.row.original;
                return <>{formatDateTime(created_at)}</>;
            }
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: rowData => {
                const {id, name} = rowData.row.original;
                const handleDelete = async e => {
                    const {isConfirmed} = await Swal.fire({
                        title: t('sure', {ns: "common"}),
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: `${t('yes', {ns: "common"})}, ${t('deleteConfirm', {ns: "common"})}`,
                        cancelButtonText: t('cancel', {ns: "common"})
                    });
                    if (isConfirmed) {
                        e.preventDefault();
                        setLoading(true);
                        await api.delete(`/school/${id}/`).then(() => {
                            toast.success(
                                `${t('title')} ${name} ${t('deleteSuccess', {ns: "common"})}.`,
                                {
                                    theme: 'colored'
                                }
                            );
                            fetchSchools(query)
                        });
                        setLoading(false);
                    }
                };
                return (
                    <CardDropdown>
                        <div className="py-2">
                            {hasPermission("school.change_school") &&
                                <Dropdown.Item onClick={() => navigate(paths.schoolChange.replace(":id", id))}>
                                    {t('edit', {ns: "common"})}
                                </Dropdown.Item>
                            }
                            <Dropdown.Divider as="div"/>
                            {hasPermission("school.delete_school") &&
                                <Dropdown.Item
                                    onClick={e => handleDelete(e)}
                                    className="text-danger"
                                >
                                    {t('delete', {ns: "common"})}
                                </Dropdown.Item>
                            }
                        </div>
                    </CardDropdown>
                );
            }
        }
    ];

    const schoolList = schools?.map(school => ({
        id: school.id,
        name: school.name,
        logo: school.logo_url,
        created_at: school.created_at,
        email: school.email,
        phone: school.phone,
        website: school.website,
    }));

    const handleSort = column => {
        if (column.canSort) {
            const {id} = column;
            const isDescending = sortingField === id && sortingDirection === 'desc';

            // Update the sorting field and direction
            setSortingField(id);
            setSortingDirection(isDescending ? 'asc' : 'desc');

            // Modify the sorting properties of the column
            column.isSorted = true;
            column.isSortedDesc = isDescending;
            column.sortingDirection = isDescending ? 'asc' : 'desc';
        }
    };

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={schoolList}
            selection
            pagination
            perPage={20}
        >
            <Card className="mb-3">
                <Card.Header>
                    <BaseTableHeader
                        table
                        fetchData={delayedLoadItems}
                        count={count}
                        addPermission={"school.add_school"}
                        creationPath={paths.schoolCreate}
                        title={"school"}
                        deletePermission={"school.delete_school"}
                        exportPermission={"school.add_schoolexportfile"}
                        data={schoolList}
                        setLoading={setLoading}
                        deleteLink={"/school/delete/school/"}
                    />
                </Card.Header>
                <Card.Body className="p-0">
                    {loading ? (
                        <Flex justifyContent="center" className="p-2 mb-2">
                            <Spinner animation={'border'} variant={'primary'}/>
                        </Flex>
                    ) : (
                        <AdvanceTable
                            handleSort={handleSort}
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                            headers={columns}
                        />
                    )}
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination
                        query={query}
                        fetch={fetchSchools}
                        count={count}
                        length={length}
                        itemsPerPage={20}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
}

export default withPermission(withTranslation(["school", "common"])(Schools), "school.view_school")