import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import {api} from '../../utils/api';
import {Link, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import SocialAuthButtons from './SocialAuthButtons';
import Divider from 'components/common/Divider';
import CSRFToken from '../../helpers/CSRFToken';
import FormError from '../errors/FormError';
import {useAppContext} from '../../providers/AppProvider';
import useQuery from "../../hooks/useQuery";
import paths from "../../routes/paths";

const forgotPasswordPaths = {
    simple: paths.simpleForgotPassword,
    split: paths.splitForgotPassword,
    card: paths.cardForgotPassword
};

const ReAuthenticateForm = ({hasLabel, layout, t, i18n}) => {
    const {setConfig} = useAppContext();
    const navigate = useNavigate();
    // State
    const [formData, setFormData] = useState({
        password: '',
    });
    const [errors, setErrors] = useState({});

    let query = useQuery()

    useEffect(() => {
        if (!query.has("next")) query.set("next", "/dashboard")
    }, [query]);

    // Handler
    const handleSubmit = e => {
        e.preventDefault();
        api
            .post('/_allauth/browser/v1/auth/reauthenticate', formData)
            .then(res => {
                setConfig('isAuthenticated', true)
                navigate(query.get("next"));
                toast.success(`${t('loginSuccess', {ns: 'common'})} ${res?.data?.data?.user?.display}`, {
                    theme: 'colored'
                });
            })
            .catch(err => {
                setErrors(err?.response?.data);
                toast.error(`${t('error', {ns: 'common'})} ${err.toString()}`, {
                    theme: 'colored'
                });
            });
    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Form onSubmit={handleSubmit}>
            <CSRFToken/>

            <Form.Group className="mb-3">
                {hasLabel && <Form.Label>{t('fields.password')}</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? t('fields.password') : ''}
                    value={formData.password}
                    name="password"
                    onChange={handleFieldChange}
                    type="password"
                />
                <FormError error={errors?.password}/>
            </Form.Group>

            <Row className="justify-content-between align-items-center">
                <Col xs="auto">
                    <Form.Check type="checkbox" id="rememberMe" className="mb-0">
                        <Form.Check.Input
                            type="checkbox"
                            name="remember"
                            checked={formData.remember}
                            onChange={e =>
                                setFormData({
                                    ...formData,
                                    remember: e.target.checked
                                })
                            }
                        />
                        <Form.Check.Label className="mb-0 text-700">
                            {t('fields.remember')}
                        </Form.Check.Label>
                    </Form.Check>
                </Col>

                <Col xs="auto">
                    <Link
                        className="fs--1 mb-0"
                        to={forgotPasswordPaths[layout]}
                    >
                        {t('fields.forgotPass')}
                    </Link>
                </Col>
            </Row>

            <Form.Group>
                <Button type="submit" color="primary" className="mt-3 w-100">
                    {t('login.title')}
                </Button>
                <FormError error={errors?.non_field_errors}/>
            </Form.Group>
            <Divider className="mt-4">{t('or')} {t('login.title')} {t('with')}</Divider>

            <SocialAuthButtons type={'login'}/>
        </Form>
    );
};

ReAuthenticateForm.propTypes = {
    layout: PropTypes.string,
    hasLabel: PropTypes.bool
};

ReAuthenticateForm.defaultProps = {
    layout: 'simple',
    hasLabel: false
};

export default ReAuthenticateForm;
