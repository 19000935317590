import {withPermission} from "../../../helpers/utils";
import {withTranslation} from "react-i18next";
import React, {Fragment, useEffect, useState} from "react";
import IconButton from "../../../components/common/IconButton";
import {api} from "../../../utils/api";
import {toast} from "react-toastify";
import {Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import QRCode from "react-qr-code";
import CSRFToken from "../../../helpers/CSRFToken";
import FormError from "../../errors/FormError";
import useQuery from "../../../hooks/useQuery";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

const AccountMobileApplication = ({t, i18n}) => {
    const [mfas, setMfas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [applicationModal, setApplicationModal] = useState(false);
    const [mfa, setMfa] = useState({})
    const [showSecret, setShowSecret] = useState(false)
    const [errors, setErrors] = useState({})
    const [modalLoading, setModalLoading] = useState(false)
    const [verificationFormData, setVerificationFormData] = useState({
        code: ""
    })

    const query = useQuery()

    query.set("page_size", "5")

    const fetchMFAs = async () => {
        setLoading(true)
        await api.get(`/_allauth/browser/v1/account/authenticators`)
            .then(res => {
                setMfas(res?.data?.data)
            })
        setLoading(false)
    }

    useEffect(() => {
        fetchMFAs()
        // eslint-disable-next-line
    }, []);

    const handleVerificationFieldChange = e => {
        setVerificationFormData({...verificationFormData, [e.target.name]: e.target.value})
    }

    const handleAddApplication = async e => {
        e.preventDefault();
        setLoading(true)
        await api.get("/_allauth/browser/v1/account/authenticators/totp")
            .catch(err => {
                setApplicationModal(!applicationModal);
                setMfa(err?.response?.data?.meta)
            })
        setLoading(false)
    }

    const handleSubmit = async e => {
        setModalLoading(true)
        await api.post("/_allauth/browser/v1/account/authenticators/totp", verificationFormData)
            .then(async res => {
                toast.success("Mobile Application successfully verified.", {theme: "colored"})
                setApplicationModal(!applicationModal)
                fetchMFAs()
            })
            .catch(err => {
                toast.error("An error has occurred.", {theme: "colored"})
                setErrors(err?.response?.data)
            })
        setModalLoading(false)
    }

    const handleDeactivate = async () => {
        const {isConfirmed} = await Swal.fire({
            title: t('sure', {ns: "common"}),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `${t('yes', {ns: "common"})}, ${t('deleteConfirm', {ns: "common"})}`,
            cancelButtonText: t('cancel', {ns: "common"})
        });
        if (isConfirmed) {
            setLoading(true);
            await api.delete(`/_allauth/browser/v1/account/authenticators/totp`).then(() => {
                toast.success(
                    `${t('title')} ${t('deleteSuccess', {ns: "common"})}`,
                    {
                        theme: 'colored'
                    }
                );
                fetchMFAs()
            });
            setLoading(false);
        }
    }

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            {mfas?.find(element => element?.type === "totp") ? (
                    <>
                        <p>Authentication using an authenticator app is active.</p>
                        <IconButton
                            icon={"lock"}
                            variant={"outline-primary"}
                            size={"sm"}
                            onClick={handleDeactivate}
                        >
                            <span className={"d-none d-lg-inline-block"}>Deactivate</span>
                        </IconButton>
                    </>
                )
                : (
                    <>
                        <p>An authenticator app is not active.</p>
                        <IconButton
                            icon={"plus"}
                            variant={"outline-primary"}
                            size={"sm"}
                            onClick={handleAddApplication}
                        >
                            <span className={"d-none d-lg-inline-block"}>Activate</span>
                        </IconButton>
                    </>
                )
            }
            <Modal centered size={"lg"} show={applicationModal} onHide={() => setApplicationModal(!applicationModal)}>
                <Modal.Header closeButton>
                    <h5>Two-factor authentication via mobile app</h5>
                </Modal.Header>
                <Modal.Body>
                    <h6>Add a mobile application</h6>
                    <p>Scan the QR code</p>
                    <Row>
                        <Col>
                            <QRCode value={mfa?.totp_url}/>
                            {showSecret && <p className={"mt-3"}>{mfa?.secret}</p>}
                            <IconButton
                                icon={"eye"}
                                variant={"outline-primary"}
                                size={"sm"}
                                className={"mt-3"}
                                onClick={() => setShowSecret(!showSecret)}
                            >
                                <span
                                    className={"d-none d-lg-inline-block"}>{!showSecret ? 'Show' : 'Hide'} secret</span>
                            </IconButton>
                        </Col>
                        <Col>
                            {modalLoading ? (
                                <Flex justifyContent="center" className="p-2 mb-2">
                                    <Spinner animation={'border'} variant={'primary'}/>
                                </Flex>
                            ) : (
                                <Form>
                                    <CSRFToken/>
                                    <Form.Group>
                                        <Form.Label>Code <span className={"text-danger"}>*</span></Form.Label>
                                        <Form.Control
                                            type={"text"}
                                            placeholder={"Code"}
                                            name={"code"}
                                            value={verificationFormData.code}
                                            onChange={handleVerificationFieldChange}
                                        />
                                        <FormError error={errors?.code}/>
                                    </Form.Group>
                                </Form>
                            )}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Flex justifyContent={'between'} alignItems={"center"} wrap={'wrap'}>
                        <IconButton
                            icon={faSave}
                            onClick={e => handleSubmit(e)}
                        >
                        <span className="d-none d-sm-inline-block ms-1">
                          {t('save', {ns: "common"})}
                        </span>
                        </IconButton>
                    </Flex>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default withPermission(withTranslation(["common"])(AccountMobileApplication), "otp_totp.view_totpdevice")
