import React, {useEffect, useState} from 'react';
import ProfileBanner from '../ProfileBanner';
import coverSrc from 'assets/img/generic/4.jpg';
import {Button, Card, Col, Row, Spinner} from 'react-bootstrap';
import ProfileSettings from './ProfileSettings';
import ExperiencesSettings from './ExperiencesSettings';
import EducationSettings from './EducationSettings';
import AccountSettings from './AccountSettings';
import ChangePassword from './ChangePassword';
import DangerZone from './DangerZone';
import {api} from '../../../utils/api';
import Flex from '../../../components/common/Flex';
import {Link, useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import VerifiedBadge from '../../../components/common/VerifiedBadge';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import ConnectionsList from '../../authentication/connections/ConnectionsList';
import paths from "../../../routes/paths";
import AccountSecurity from "./AccountSecurity";

const Settings = () => {
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        cover: null,
        avatar: null,
        first_name: '',
        last_name: '',
        email: '',
        number: '',
        heading: '',
        introduction: '',
        birth_date: ''
    });

    const navigate = useNavigate();

    const getUser = async () => {
        setLoading(true);
        await api.get('/account/current_user/').then(res => {
            setUser(res?.data);
            setFormData({
                first_name: res?.data?.first_name,
                last_name: res?.data?.last_name,
                email: res?.data?.email,
                number: res?.data?.number,
                heading: res?.data?.heading,
                introduction: res?.data?.introduction,
                birth_date: res?.data?.birth_date
            });
        });
        setLoading(false);
    };

    useEffect(() => {
        getUser();
    }, []);

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <>
            <ProfileBanner>
                <ProfileBanner.Header
                    formData={formData}
                    setFormData={setFormData}
                    coverSrc={user?.cover_url ? user?.cover_url : coverSrc}
                    avatar={user?.url_path}
                    className="mb-8"
                />
                <ProfileBanner.Body>
                    <Row>
                        <Col lg={8}>
                            <h4 className="mb-1">
                                {user?.full_name} {user?.is_active && <VerifiedBadge/>}
                            </h4>
                            <h5 className="fs-0 fw-normal">{user?.heading}</h5>
                            <p className="text-500">{user?.address_names}</p>
                            <div className="border-dashed border-bottom my-4 d-lg-none"/>
                        </Col>
                        <Col className="ps-2 ps-lg-3">
                            <Link to={`/user/followers`}>
                                <Flex alignItems="center" className="mb-2">
                                    <FontAwesomeIcon
                                        icon="user-circle"
                                        className="me-2 text-700"
                                        style={{height: '30px', width: '30px'}}
                                    />
                                    <div className="flex-1">
                                        <h6 className="mb-0">
                                            See followers ({user?.followed_by?.length})
                                        </h6>
                                    </div>
                                </Flex>
                            </Link>
                        </Col>
                    </Row>
                </ProfileBanner.Body>
            </ProfileBanner>
            <Row className="g-3">
                <Col lg={8}>
                    <ProfileSettings
                        formData={formData}
                        setFormData={setFormData}
                        user={user}
                        getUser={getUser}
                    />
                    <ExperiencesSettings
                        experiences={user?.experience_set}
                        getUser={getUser}
                    />
                    <EducationSettings
                        educationData={user?.education_set}
                        getUser={getUser}
                    />
                </Col>
                <Col lg={4}>
                    <div className="sticky-sidebar">
                        <AccountSettings settings={user?.settings}/>
                        <Card className={'mb-3'}>
                            <FalconCardHeader
                                title="Social Account Connections"
                                endEl={
                                    <Button
                                        onClick={() => navigate(paths.userConnections)}
                                        size="sm"
                                        variant="link"
                                        className="p-0"
                                    >
                                        Show All
                                    </Button>
                                }
                            />
                            <Card.Body>
                                <ConnectionsList showDate={false} showSocialButtons={false}/>
                            </Card.Body>
                        </Card>
                        <AccountSecurity user={user}/>
                        <ChangePassword user={user} getUser={getUser}/>
                        <DangerZone id={user?.id}/>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default Settings;
