import {withPermission} from "../../../helpers/utils";
import {withTranslation} from "react-i18next";
import React, {Fragment, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {api} from "../../../utils/api";
import paths from "../../../routes/paths";
import {Alert, Button, Card, Spinner, Form, Table} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import IconButton from "../../../components/common/IconButton";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {usePermissions} from "../../../providers/PermissionsProvider";
import SimpleBarReact from "simplebar-react";

const SiteDetail = ({t, i18n}) => {
    const [loading, setLoading] = useState(false);
    const [site, setSite] = useState({});

    const {id} = useParams();
    const navigate = useNavigate()
    const {hasPermission} = usePermissions()

    const fetchSiteDetails = async () => {
        setLoading(true);
        await api.get(`/site/${id}/`)
            .then(res => setSite(res?.data))
            .catch(err => {
                if (err?.response?.status === 404) navigate(paths.error404)
            })
        setLoading(false)
    }

    const checkVerification = async (domainId) => {
        setLoading(true);
        try {
            await api.post(`/site/verify/`, {id: domainId})
                .then(res => setSite(res?.data))
        } catch (error) {
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchSiteDetails()
        // eslint-disable-next-line
    }, []);

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <Card className={"d-print-none"}>
                <Card.Header className="d-flex flex-between-center">
                    <Flex alignItems={"center"}>
                        <IconButton
                            onClick={() => navigate(-1)}
                            variant="falcon-default"
                            size="sm"
                            icon="arrow-left"
                        />
                        <h5 className={"ms-2"}>{site?.name} - {site?.domain}{site?.type === "subdomain" ? ".nexameet.com" : ""}</h5>
                    </Flex>
                    <Flex alignItems={"center"} wrap={"wrap"}>
                        {!site?.is_verified &&
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon={faSyncAlt}
                                className={"ms-2"}
                                transform="shrink-3"
                                onClick={() => checkVerification(site?.id)}
                            >
                                <span className="d-none d-sm-inline-block ms-1">Refresh</span>
                            </IconButton>
                        }
                        {hasPermission("sites.change_site") &&
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="pencil-alt"
                                className={"ms-2"}
                                transform="shrink-3"
                                onClick={() => navigate(paths.siteEdit.replace(":id", site?.id))}
                            >
                                <span className="d-none d-sm-inline-block ms-1">Edit</span>
                            </IconButton>
                        }
                        {hasPermission("sites.delete_site") &&
                            <IconButton
                                variant="danger"
                                size="sm"
                                icon="trash-alt"
                                className={"ms-2"}
                                transform="shrink-3"
                                onClick={() => {
                                }}
                            >
                                <span className="d-none d-sm-inline-block ms-1">Delete</span>
                            </IconButton>
                        }
                    </Flex>
                </Card.Header>
            </Card>
            {site?.type === "custom" && (
                <Card className={"mt-3"}>
                    <Card.Header as={"h5"}>
                        Verification Status
                    </Card.Header>
                    <Card.Body>
                        {site?.id && (
                            <Form.Group className="mb-3">
                                <Alert variant={site?.is_verified ? "success" : "warning"}>
                                    {site?.is_verified ? "Verified ✅" : "Pending Verification ⏳"}
                                </Alert>
                                {!site?.is_verified && (
                                    <Flex justifyContent={"between"} alignItems={"center"} wrap={"wrap"}>
                                        {site.verification_token && (
                                            <small className="text-muted">
                                                Add the following **TXT** record to your DNS for verification: <br/>
                                                <strong>{site.verification_token}</strong>
                                            </small>
                                        )}
                                        <Button variant="secondary" onClick={() => checkVerification(site.id)}
                                                disabled={loading}>
                                            {loading ? <Spinner animation="border" size="sm"/> : "Check Verification"}
                                        </Button>
                                    </Flex>
                                )}
                            </Form.Group>
                        )}
                    </Card.Body>
                </Card>
            )}
            {site?.type === "custom" && (
                <Card className={"mt-3"}>
                    <Card.Header as={"h5"}>
                        {site?.type_display} Configuration
                    </Card.Header>
                    <Card.Body>
                        <small className="text-muted">
                            Add the following records to your DNS:
                        </small>
                        <SimpleBarReact>
                            <Table>
                                <thead>
                                <tr>
                                    <td>Name</td>
                                    <td>Type</td>
                                    <td>Value</td>
                                </tr>
                                </thead>
                                <tbody>
                                {site?.type === "subdomain" ? (
                                    <tr>
                                        <td>@</td>
                                        <td>CNAME</td>
                                        <td>{site?.domain}.nexameet.com</td>
                                    </tr>
                                ) : (
                                    <>
                                        <tr>
                                            <td>{site?.domain}</td>
                                            <td>A</td>
                                            <td>76.76.21.21</td>
                                        </tr>
                                        <tr>
                                            <td>{site?.domain}</td>
                                            <td>CNAME</td>
                                            <td>nexameet.com</td>
                                        </tr>
                                    </>
                                )}
                                </tbody>
                            </Table>
                        </SimpleBarReact>
                    </Card.Body>
                </Card>
            )}
        </Fragment>
    )
}

export default withPermission(withTranslation(["site", "common"])(SiteDetail), "sites.view_site")