import React, {useState} from "react";
import {Button, Form, Spinner} from "react-bootstrap";
import CSRFToken from "../../helpers/CSRFToken";
import FormError from "../errors/FormError";
import Flex from "../../components/common/Flex";
import {api} from "../../utils/api";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import {useAppContext} from "../../providers/AppProvider";
import paths from "../../routes/paths";

const CodeLoginConfirmForm = ({t, i18n, hasLabel}) => {
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        code: ''
    });

    const {setConfig} = useAppContext();
    const navigate = useNavigate()
    const query = useQuery()

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = e => {
        setLoading(true)
        e.preventDefault();
        api.post('/_allauth/browser/v1/auth/code/confirm', formData)
            .then(res => {
                setConfig('isAuthenticated', true);
                setConfig('group', res?.data?.user?.groups_name);
                setConfig('permissions', res?.data?.user?.permissions);
                setConfig('isSuperUser', res?.data?.user?.is_superuser);
                setConfig('isStaff', res?.data?.user?.is_staff);
                setConfig('isActivity', res?.data?.user?.is_activity);
                setConfig('isCompany', res?.data?.user?.is_company);
                setConfig('company', res?.data?.user?.company);
                setConfig('avatar', res?.data?.user?.url_path);
                setConfig(
                    'activities_joined',
                    res?.data?.user?.member_in?.map(m => m?.activity)
                );
                setConfig('activity', res?.data?.user?.activity);
                setConfig('settings', JSON.stringify(res?.data?.user?.settings));
                navigate(query.get("next"));
                toast.success(`${t('loginSuccess', {ns: 'common'})} ${res?.data?.user?.username}`, {
                    theme: 'colored'
                });
            })
            .catch(err => {
                if (err?.response?.status === 400) {
                    setErrors(err.response.data);
                } else if (err?.response?.status === 401) {
                    toast.success("Login code successfully sent.", {theme: "colored"})
                    const flow = err?.response?.data?.data?.flows?.find(element => element?.id === "mfa_authenticate" && element?.is_pending)
                    flow?.types?.map(type => query.append("type", type))
                    if (flow?.types[0] === "recovery_codes")
                        navigate(`${paths.splitRecoveryCodeLogin}?${query.toString()}`)
                    if (flow?.types[0] === "webauthn")
                        navigate(`${paths.splitWebauthnLogin}?${query.toString()}`)
                    if (flow?.types[0] === "totp")
                        navigate(`${paths.splitTOTPLogin}?${query.toString()}`)
                } else if (err?.response?.status === 409) {
                    navigate(-1)
                }
            })
        setLoading(false)
    }

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Form onSubmit={handleSubmit}>
            <CSRFToken/>
            <Form.Group className="mb-3">
                {hasLabel && <Form.Label>Code</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? 'Code' : ''}
                    value={formData.code}
                    name="code"
                    onChange={handleFieldChange}
                    type="text"
                />
                <FormError error={errors?.code}/>
            </Form.Group>

            <Form.Group>
                <Button type="submit" color="primary" className="mt-3 w-100">
                    Sign In
                </Button>
            </Form.Group>
        </Form>
    )
}

export default CodeLoginConfirmForm