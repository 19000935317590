import React, {useEffect} from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import classNames from 'classnames';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import Footer from 'components/footer/Footer';
import {useAppContext} from "../providers/AppProvider";
import useQuery from "../hooks/useQuery";
import paths from "../routes/paths";
import {api} from "../utils/api";
import ErrorBoundary from "../pages/errors/ErrorBoundary";

const MainLayout = () => {
    const {hash, pathname} = useLocation();
    const isKanban = pathname.includes('kanban');
    // const isChat = pathname.includes('chat');

    const {
        config: {isFluid, navbarPosition, isAuthenticated}
    } = useAppContext();

    let query = useQuery()

    const recordUserActivity = async (routeVisited) => {
        await api.get("/account/setcsrf/").then(async res => {
            await api.post('/onlineactivity/', {route_visited: routeVisited}, {
                headers: {
                    "X-CSRFToken": res?.data?.csrftoken
                }
            }).catch(() => {
            })
        })
    };


    useEffect(() => {
        setTimeout(() => {
            if (hash) {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({block: 'start', behavior: 'smooth'});
                }
            }
        }, 0);
    }, [hash]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        if (!isAuthenticated)
            query.set("next", `${pathname}`)
    }, [isAuthenticated, pathname, query]);

    useEffect(() => {
        recordUserActivity(pathname)
    }, [pathname]);

    if (!isAuthenticated) return <Navigate to={`${paths.splitReauthenticate}?next=${pathname}?${query.toString()}`}
                                           replace/>;

    return (
        <div className={isFluid ? 'container-fluid' : 'container'}>
            {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
                <NavbarVertical/>
            )}
            <div className={classNames('content', {'pb-0': isKanban})}>
                <NavbarTop/>
                {/*------ Main Routes ------*/}
                <ErrorBoundary>
                    <Outlet/>
                </ErrorBoundary>
                {!isKanban && <Footer/>}
            </div>
        </div>
    );
};

export default MainLayout;