import React, {useState} from "react";
import {Button, Form, Spinner} from "react-bootstrap";
import CSRFToken from "../../helpers/CSRFToken";
import FormError from "../errors/FormError";
import Flex from "../../components/common/Flex";
import {api} from "../../utils/api";
import {toast} from "react-toastify";
import {Link, useNavigate} from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import {useAppContext} from "../../providers/AppProvider";
import Divider from "../../components/common/Divider";
import paths from "../../routes/paths";

const RecoveryCodeLoginForm = ({t, i18n, hasLabel}) => {
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        code: ''
    });

    const {setConfig} = useAppContext();
    const navigate = useNavigate()
    const query = useQuery()

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = e => {
        setLoading(true)
        e.preventDefault();
        api.post('/_allauth/browser/v1/auth/2fa/authenticate', formData)
            .then(async res => {
                setConfig('isAuthenticated', true);
                await api.get("/account/current_user/")
                    .then(res => {
                        setConfig('group', res?.data?.groups_name);
                        setConfig('permissions', res?.data?.permissions);
                        setConfig('isSuperUser', res?.data?.is_superuser);
                        setConfig('isStaff', res?.data?.is_staff);
                        setConfig('isActivity', res?.data?.is_activity);
                        setConfig('isCompany', res?.data?.is_company);
                        setConfig('company', res?.data?.company);
                        setConfig('avatar', res?.data?.url_path);
                        setConfig(
                            'activities_joined',
                            res?.data?.member_in?.map(m => m?.activity)
                        );
                        setConfig('activity', res?.data?.activity);
                        setConfig('settings', JSON.stringify(res?.data?.settings));
                        navigate(query.get("next"));
                        toast.success(`${t('loginSuccess', {ns: 'common'})} ${res?.data?.username}`, {
                            theme: 'colored'
                        });
                    })
            })
            .catch(err => {
                if (err?.response?.status === 400) {
                    setErrors(err.response.data?.errors);
                } else if (err?.response?.status === 401) {
                    toast.success("Login code successfully sent.", {theme: "colored"})
                    navigate(`?${query.toString()}`)
                } else if (err?.response?.status === 409) {
                    navigate(-1)
                }
            })
        setLoading(false)
    }

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Form onSubmit={handleSubmit}>
            <CSRFToken/>
            <Form.Group className="mb-3">
                {hasLabel && <Form.Label>Code</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? 'Code' : ''}
                    value={formData.code}
                    name="code"
                    onChange={handleFieldChange}
                    type="text"
                />
                <FormError error={errors?.message}/>
            </Form.Group>

            <Form.Group>
                <Button type="submit" color="primary" className="mt-3 w-100">
                    Sign In
                </Button>
            </Form.Group>

            <Divider className="mt-4">Alternative Options</Divider>
            {query.has("type", "totp") &&
                <Link to={`${paths.splitTOTPLogin}?${query.toString()}`}>Use your authenticator app</Link>}
            {query.has("type", "webauthn") &&
                <Link to={`${paths.splitWebauthnLogin}?${query.toString()}`}>Use a security key</Link>}
        </Form>
    )
}

export default RecoveryCodeLoginForm