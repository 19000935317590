import bgImg from "../../../assets/img/generic/10.jpg";
import Flex from "../../../components/common/Flex";
import {Link, useNavigate} from "react-router-dom";
import paths from "../../../routes/paths";
import AuthSplitLayout from "../../../layouts/AuthSplitLayout";
import React, {useEffect} from "react";
import useQuery from "../../../hooks/useQuery";
import {withTranslation} from "react-i18next";
import ReAuthenticateForm from "../ReAuthenticateForm";
import {api} from "../../../utils/api";

const ReAuthenticate = ({t, i18n}) => {
    let query = useQuery()
    const navigate = useNavigate()
    const getAuthenticators = async () => {
        await api.get('/_allauth/browser/v1/account/authenticators')
            .catch(err => {
                if (err?.response?.status === 401) navigate(paths.splitLogin + '?' + query.toString())
            })
    }

    useEffect(() => {
        getAuthenticators()
        // eslint-disable-next-line
    }, []);

    return (
        <AuthSplitLayout bgProps={{image: bgImg, position: '50% 20%'}}>
            <Flex alignItems="center" justifyContent="between">
                <h3>{t("login.title")}</h3>
                <p className="mb-0 fs--1">
                    <span className="fw-semi-bold">{t('login.newUser')} </span>
                    <Link to={`${paths.splitRegister}?${query.toString()}`}>{t('login.createAccount')}</Link>
                </p>
            </Flex>
            <ReAuthenticateForm layout="split" hasLabel t={t} i18n={i18n}/>
        </AuthSplitLayout>
    )
}

export default withTranslation(["authentication", "common"])(ReAuthenticate);