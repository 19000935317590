import {withPermission} from "../../../helpers/utils";
import {withTranslation} from "react-i18next";
import React, {Fragment, useEffect, useState} from "react";
import {Modal, Spinner} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import IconButton from "../../../components/common/IconButton";
import {api} from "../../../utils/api";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import paths from "../../../routes/paths";

const AccountBackupCodes = ({t, i18n}) => {
    const [codes, setCodes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [mfas, setMfas] = useState([]);
    const [codesModal, setCodesModal] = useState(false)

    const navigate = useNavigate()

    const generateCodes = async () => {
        setLoading(true);
        await api.post("/_allauth/browser/v1/account/authenticators/recovery-codes")
            .then(res => {
                toast.success("Recovery codes successfully generated.", {theme: "colored"})
                setCodes(res?.data?.data?.unused_codes)
                setCodesModal(!codesModal)
            })
            .catch(err => {
                if (err?.response?.status === 401) {
                    navigate(paths.splitReauthenticate)
                }
                toast.error("An error has occurred.", {theme: "colored"})
            })
        setLoading(false)
    }

    const fetchRecoveryCodes = async () => {
        setLoading(true)
        await api.get("/_allauth/browser/v1/account/authenticators/recovery-codes")
            .then(res => {
                setCodes(res?.data?.data?.unused_codes)
                setCodesModal(!codesModal)
            })
            .catch(err => {
                toast.error("An error has occurred.", {theme: "colored"})
            })
        setLoading(false)
    }

    const fetchMFAs = async () => {
        setLoading(true)
        await api.get(`/_allauth/browser/v1/account/authenticators`)
            .then(res => {
                setMfas(res?.data?.data)
            })
        setLoading(false)
    }

    useEffect(() => {
        fetchMFAs()
        // eslint-disable-next-line
    }, []);

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            {mfas?.find(element => element?.type === "recovery_codes") ? (
                <>
                    <p>There are {mfas?.find(element => element?.type === "recovery_codes")?.unused_code_count} out of
                        {' ' + mfas?.find(element => element?.type === "recovery_codes")?.total_code_count} recovery
                        codes
                        available.</p>
                    <IconButton
                        icon={"eye"}
                        variant={"outline-primary"}
                        size={"sm"}
                        className={"me-2"}
                        onClick={fetchRecoveryCodes}
                    >
                        <span className={"d-none d-lg-inline-block"}>View</span>
                    </IconButton>
                    <IconButton
                        icon={"plus"}
                        variant={"outline-primary"}
                        size={"sm"}
                        onClick={generateCodes}
                    >
                        <span className={"d-none d-lg-inline-block"}>Regenerate</span>
                    </IconButton>
                    <Modal centered show={codesModal} onHide={() => setCodesModal(!codesModal)}>
                        <Modal.Header closeButton>
                            <h5>Recovery Codes</h5>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Use these codes if you have lost your phone, save them in a secure place.</p>
                            <ul>
                                {codes?.map(code => <li>{code}</li>)}
                            </ul>
                        </Modal.Body>
                    </Modal>
                </>
            ) : (
                <>
                    <p>Use these codes if you have lost your phone, or do not have access to it.</p>
                    <IconButton
                        icon={"plus"}
                        variant={"outline-primary"}
                        size={"sm"}
                        onClick={generateCodes}
                    >
                        <span className={"d-none d-lg-inline-block"}>Generate</span>
                    </IconButton>
                </>
            )}
        </Fragment>
    )
}

export default withPermission(withTranslation(["common"])(AccountBackupCodes), "mfa.view_authenticator")