import React from 'react';
import AuthCardLayout from 'layouts/AuthCardLayout';
import VerifyMailKeyContent from "../VerifyMailKeyContent";

const VerifyMail = () => (
    <AuthCardLayout>
        <div className="text-center">
            <VerifyMailKeyContent layout="card" email="xyz@abc.com" titleTag="h3"/>
        </div>
    </AuthCardLayout>
);

export default VerifyMail;
