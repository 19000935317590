import {withTranslation} from "react-i18next";
import {withPermission} from "../../helpers/utils";
import React, {useCallback, useEffect, useState} from "react";
import useQuery from "../../hooks/useQuery";
import {useAppContext} from "../../providers/AppProvider";
import {Link, useNavigate} from "react-router-dom";
import paths from "../../routes/paths";
import {api} from "../../utils/api";
import {debounce} from "lodash";
import Flex from "../../components/common/Flex";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import CardDropdown from "../../components/common/CardDropdown";
import {Card, Dropdown, Spinner} from "react-bootstrap";
import AdvanceTableWrapper from "../../components/common/advance-table/AdvanceTableWrapper";
import BaseTableHeader from "../BaseTableHeader";
import AdvanceTable from "../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../components/common/advance-table/AdvanceTablePagination";
import SoftBadge from "../../components/common/SoftBadge";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Sites = ({t, i18n}) => {
    const [sites, setSites] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortingField, setSortingField] = useState('created_at'); // Set the initial sorting field
    const [sortingDirection, setSortingDirection] = useState('desc');
    const [count, setCount] = useState(0);
    const [length, setLength] = useState(0);
    const query = useQuery();

    const {
        config: {isAuthenticated},
        setConfig
    } = useAppContext();

    const navigate = useNavigate();

    const fetchSites = async query => {
        setLoading(true);
        const sortingParam = `${
            sortingDirection === 'desc' ? '-' : ''
        }${sortingField}`;

        query.set('ordering', sortingParam);

        // Cancel the previous request if it exists
        await api.get(`/site/?${query.toString()}`)
            .then(res => {
                setSites(res?.data?.results);
                setCount(res?.data?.count);
            })
            .catch((error) => {
                if (error?.response?.status === 404) navigate(paths.error404);
                if (error?.response?.status === 500) navigate(paths.error500);
                if (error?.response?.status === 403)
                    setConfig("isAuthenticated", !isAuthenticated);
            });
        setLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedLoadItems = useCallback(debounce(fetchSites, 600), []);

    useEffect(() => {
        fetchSites(query);
        setLength(sites.length);
        navigate(`?${query.toString()}`);
        // eslint-disable-next-line
    }, [sortingField, sortingDirection]);

    const columns = [
        {
            accessor: 'name',
            Header: t('fields.name'),
            canSort: true,
            isSorted: true,
            sortingDirection: sortingField === 'name' && sortingDirection,
            headerProps: {className: 'pe-1'},
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const {id, name} = rowData.row.original
                return <Link to={paths.siteDetail.replace(":id", id)}>{name}</Link>
            }
        },
        {
            accessor: 'domain',
            Header: t('fields.domain'),
            canSort: true,
            isSorted: true,
            sortingDirection: sortingField === 'domain' && sortingDirection,
            headerProps: {className: 'pe-1'},
            cellProps: {
                className: 'py-2'
            },
        },
        {
            accessor: 'type',
            Header: t('fields.type'),
            canSort: true,
            sortingDirection: sortingField === 'type' && sortingDirection,
            isSorted: false,
            cellProps: {
                className: 'py-2'
            },
            headerProps: {className: 'pe-3'}
        },
        {
            accessor: 'is_verified',
            Header: t('fields.is_verified'),
            canSort: true,
            sortingDirection: sortingField === 'is_verified' && sortingDirection,
            isSorted: false,
            cellProps: {
                className: 'py-2'
            },
            headerProps: {className: 'pe-3'},
            Cell: rowData => {
                const {is_verified} = rowData.row.original;
                return (
                    <SoftBadge
                        pill
                        bg={classNames({
                            success: is_verified,
                            warning: !is_verified
                        })}
                        className="d-flex flex-center"
                    >
                        <p className="mb-0">
                            {is_verified && t('fields.is_verified')}
                            {!is_verified && t('fields.not_verified')}
                        </p>
                        <FontAwesomeIcon
                            icon={classNames({
                                check: is_verified,
                                ban: !is_verified
                            })}
                            transform="shrink-2"
                            className="ms-1"
                        />
                    </SoftBadge>
                );
            }
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: rowData => {
                const {id, name} = rowData.row.original;
                const handleDelete = async e => {
                    const {isConfirmed} = await Swal.fire({
                        title: t('sure', {ns: 'common'}),
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: t('cancel', {ns: "common"}),
                        confirmButtonText: `${t('yes', {ns: 'common'})}, ${t('deleteConfirm', {ns: 'common'})}`
                    });
                    if (isConfirmed) {
                        e.preventDefault();
                        setLoading(true);
                        await api.delete(`/site/${id}/`).then(() => {
                            toast.success(`${t('title')} ${name} ${t('deleteSuccess', {ns: 'common'})}.`, {
                                theme: 'colored'
                            });
                            fetchSites(query)
                        });
                        setLoading(false);
                    }
                };
                return (
                    <CardDropdown>
                        <div className="py-2">
                            <Dropdown.Item onClick={() => navigate(paths.siteEdit.replace(":id", id))}>
                                {t('edit', {ns: "common"})}
                            </Dropdown.Item>
                            <Dropdown.Divider as="div"/>
                            <Dropdown.Item
                                onClick={e => handleDelete(e)}
                                className="text-danger"
                            >
                                {t('delete', {ns: "common"})}
                            </Dropdown.Item>
                        </div>
                    </CardDropdown>
                );
            }
        }
    ];

    const siteList = sites.map(site => ({
        id: site.id,
        name: site.name,
        domain: site.domain,
        is_verified: site.is_verified,
        type: site.type_display
    }));

    const handleSort = column => {
        if (column.canSort) {
            const {id} = column;
            const isDescending = sortingField === id && sortingDirection === 'desc';

            // Update the sorting field and direction
            setSortingField(id);
            setSortingDirection(isDescending ? 'asc' : 'desc');

            // Modify the sorting properties of the column
            column.isSorted = true;
            column.isSortedDesc = isDescending;
            column.sortingDirection = isDescending ? 'asc' : 'desc';
        }
    };

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={siteList}
            selection
            pagination
            perPage={20}
        >
            <Card className="mb-3">
                <Card.Header>
                    <BaseTableHeader
                        table
                        fetchData={delayedLoadItems}
                        count={count}
                        addPermission={"sites.add_site"}
                        creationPath={paths.siteCreate}
                        title={"site"}
                        deletePermission={"sites.delete_site"}
                    />
                </Card.Header>
                <Card.Body className="p-0">
                    {loading ? (
                        <Flex justifyContent="center" className="p-2 mb-2">
                            <Spinner animation={'border'} variant={'primary'}/>
                        </Flex>
                    ) : (
                        <AdvanceTable
                            handleSort={handleSort}
                            table
                            headerClassName="bg-200 text-900 text-nowrap text-center align-middle"
                            rowClassName="align-middle white-space-nowrap text-center"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                            headers={columns}
                        />
                    )}
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination
                        query={query}
                        fetch={fetchSites}
                        count={count}
                        length={length}
                        itemsPerPage={20}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
}

export default withTranslation(["site", "common"])(withPermission(Sites, "sites.view_site"))