import React, {Fragment, useEffect, useState} from 'react';
import {Alert, Button, Card, Carousel, Col, ProgressBar, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import event from 'assets/img/gallery/appointment-agenda-reminder-personal-organizer-calendar-concept.jpg';
import networking from 'assets/img/gallery/networked-cityscape-with-connected-icons.jpg';
import editing from 'assets/img/icons/spot-illustrations/img.png';
import editing1 from 'assets/img/icons/spot-illustrations/img_1.png';
import {api} from "../utils/api";
import paths from "../routes/paths";
import Flex from "../components/common/Flex";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBuilding, faEnvelopeOpen} from "@fortawesome/free-solid-svg-icons";

const Starter = () => {
    const [user, setUser] = useState({})
    const [progress, setProgress] = useState(0);
    const [showAlert, setShowAlert] = useState(true);

    const getCurrentUser = async () => {
        await api.get("/account/current_user/").then((response) => {
            setUser(response?.data)
            setProgress(100 / 3)
        })
    }

    useEffect(() => {
        getCurrentUser()
        // eslint-disable-next-line
    }, []);

    return (
        <Fragment fluid className="p-4">
            {showAlert && (
                <Alert variant="info" onClose={() => setShowAlert(false)} dismissible>
                    <h4>Welcome to Nexameet, {user?.full_name}!</h4>
                    <p>Get started by joining or creating an organization and exploring the platform.</p>
                </Alert>
            )}
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Header as={"h5"}>Onboarding Progress</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col lg={4} key={'profile'}>
                                    <Flex className="position-relative">
                                        <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                                            <FontAwesomeIcon icon={"user"} className={`text-primary`}/>
                                        </div>
                                        <div className="flex-1">
                                            <Link to={paths.userProfile} className="stretched-link text-800">
                                                <h6 className="mb-0">Setting up a profile</h6>
                                            </Link>
                                            <p className="mb-0 fs--2 text-500 ">Set up a visible profile</p>
                                        </div>
                                    </Flex>
                                </Col>
                                <Col lg={4} key={'join'}>
                                    <Flex className="position-relative">
                                        <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                                            <FontAwesomeIcon icon={faEnvelopeOpen} className={`text-warning`}/>
                                        </div>
                                        <div className="flex-1">
                                            <Link to={paths.activityList} className="stretched-link text-800">
                                                <h6 className="mb-0">Join an existing activity</h6>
                                            </Link>
                                            <p className="mb-0 fs--2 text-500 ">Be a member of an existing organization
                                                and start networking.</p>
                                        </div>
                                    </Flex>
                                </Col>
                                <Col lg={4} key={'create'}>
                                    <Flex className="position-relative">
                                        <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                                            <FontAwesomeIcon icon={faBuilding} className={`text-success`}/>
                                        </div>
                                        <div className="flex-1">
                                            <Link to={paths.activityCreate} className="stretched-link text-800">
                                                <h6 className="mb-0">Create your own organization!</h6>
                                            </Link>
                                            <p className="mb-0 fs--2 text-500 ">Be a manager and organize your own
                                                organization.</p>
                                        </div>
                                    </Flex>
                                </Col>
                            </Row>
                            <Row className={"mt-2"}>
                                <Col>
                                    <ProgressBar now={progress} label={`${progress.toFixed(2)}%`}/>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card className={"mt-3"}>
                        <Card.Body className="overflow-hidden p-lg-6">
                            <Row className="align-items-center justify-content-between">
                                <Col lg={6}>
                                    <img src={editing} className="img-fluid" alt=""/>
                                </Col>
                                <Col lg={6} className="ps-lg-4 my-5 text-center text-lg-start">
                                    <h3 className="text-primary">Join existing organization!</h3>
                                    <p className="lead">Be a member of an existing organization and start
                                        networking.</p>
                                    <Button
                                        variant="success"
                                        as={Link}
                                        to="/activities/activity"
                                    >
                                        Getting started
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card className={"mt-3"}>
                        <Card.Body className="overflow-hidden p-lg-6">
                            <Row className="align-items-center justify-content-between">
                                <Col lg={6}>
                                    <img src={editing1} className="img-fluid" alt=""/>
                                </Col>
                                <Col lg={6} className="ps-lg-4 my-5 text-center text-lg-start">
                                    <h3 className="text-primary">Create your own organization!</h3>
                                    <p className="lead">Be a manager and organize your own organization and start
                                        managing.</p>
                                    <Button
                                        variant="success"
                                        as={Link}
                                        to="/activities/activity/new"
                                    >
                                        Getting started
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col>
                    <Card>
                        <Card.Header as={'h5'}>Explore Nexameet Features</Card.Header>
                        <Card.Body>
                            <Carousel>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={event}
                                        alt="Event Planning"
                                    />
                                    <Carousel.Caption>
                                        <h6>Plan and Manage Events</h6>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={networking}
                                        alt="Community Networking"
                                    />
                                    <Carousel.Caption>
                                        <h6>Build Professional Connections</h6>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            </Carousel>
                        </Card.Body>
                    </Card>
                </Col>

                <Col lg={12}>
                    <Card className="text-center p-3 mt-3">
                        <h6>Need Help?</h6>
                        <p>Check out our <Link to={paths.tutorial}>tutorials</Link> or contact support.</p>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default Starter;
