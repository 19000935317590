export const rootPaths = {
    root: "/",
    dashboardRoot: "dashboard",
    accountRoot: "accounts",
    appsRoot: "app",
    membersRoot: "members",
    addressRoot: "address",
    enoticeRoot: "enotices",
    eventsRoot: "events",
    socialRoot: "feed",
    billingRoot: "billing",
    pricingRoot: "pricing",
    managementRoot: "management",
    activitiesRoot: "activities",
    pagesRoot: "pages",
    authRoot: "authentication",
    authSimpleRoot: "simple",
    authCardRoot: "card",
    authSplitRoot: "split",
    userRoot: "user",
    groupRoot: "group",
    memberRoot: "member",
    roleRoot: "role",
    errorsRoot: "errors",
    organizersRoot: "organizers",
    sponsorsRoot: "sponsors",
    sponsorFileRoot: "sponsorship",
    companyRoot: "companies",
    activityRoot: "activity",
    requestRoot: "request",
    reportsRoot: "reports",
    transactionsRoot: "transactions",
    rulesRoot: "rule",
    privacyRoot: "privacy-policy",
    termsRoot: "terms-of-service",
    contractRoot: "contract",
    membershipRoot: "membership",
    currencyRoot: "currency",
    subscriptionRoot: "subscription",
    landingRoot: "l",
    timelineRoot: "timeline",
    blogRoot: "blog",
    testimonialRoot: "testimonial",
    periodicRoot: "periodic-task",
    bugRoot: "bug",
    tutorialRoot: "tutorial",
    newsletterRoot: "newsletter",
    providerRoot: "provider",
    supportRoot: "support-desk",
    collaborationRoot: "collaboration-center",
    venueRoot: "venue",
    siteRoot: "site",
    schoolRoot: "school"
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    changelog: "/changelog",
    widgets: "/widgets",
    landing: "/",
    privacyPolicy: `/${rootPaths.privacyRoot}`,
    termsConditions: `/${rootPaths.termsRoot}`,
    offers: `/offers`,
    calendar: `/${rootPaths.appsRoot}/calendar`,
    starter: `/${rootPaths.pagesRoot}/starter`,
    error404: `/${rootPaths.errorsRoot}/404`,
    error403: `/${rootPaths.errorsRoot}/403`,
    error500: `/${rootPaths.errorsRoot}/500`,
    users: `/${rootPaths.accountRoot}/${rootPaths.userRoot}`,
    usersAdd: `/${rootPaths.accountRoot}/${rootPaths.userRoot}/new`,
    usersExport: `/${rootPaths.accountRoot}/${rootPaths.userRoot}/export`,
    usersEdit: `/${rootPaths.accountRoot}/${rootPaths.userRoot}/:id/edit`,
    usersDetail: `/${rootPaths.accountRoot}/${rootPaths.userRoot}/:id/detail`,
    usersHistory: `/${rootPaths.accountRoot}/${rootPaths.userRoot}/:id/history`,
    usersFollowers: `/${rootPaths.accountRoot}/${rootPaths.userRoot}/:id/followers`,
    groups: `/${rootPaths.accountRoot}/${rootPaths.groupRoot}`,
    groupsAdd: `/${rootPaths.accountRoot}/${rootPaths.groupRoot}/new`,
    groupsEdit: `/${rootPaths.accountRoot}/${rootPaths.groupRoot}/:id/edit`,
    groupsExport: `/${rootPaths.accountRoot}/${rootPaths.groupRoot}/export`,
    address: `/${rootPaths.addressRoot}`,
    createAddress: `/${rootPaths.addressRoot}/new`,
    addressExports: `/${rootPaths.addressRoot}/export`,
    editAddress: `/${rootPaths.addressRoot}/:id/edit`,
    members: `/${rootPaths.membersRoot}/${rootPaths.memberRoot}`,
    membersAdd: `/${rootPaths.membersRoot}/${rootPaths.memberRoot}/new`,
    membersExports: `/${rootPaths.membersRoot}/${rootPaths.memberRoot}/export`,
    membersImports: `/${rootPaths.membersRoot}/${rootPaths.memberRoot}/import`,
    importMember: `/${rootPaths.membersRoot}/${rootPaths.memberRoot}/importer`,
    membersImportDetail: `/${rootPaths.membersRoot}/${rootPaths.memberRoot}/import/:id/detail`,
    membersEdit: `/${rootPaths.membersRoot}/${rootPaths.memberRoot}/:id/edit`,
    membersDetail: `/${rootPaths.membersRoot}/${rootPaths.memberRoot}/:id/detail`,
    roles: `/${rootPaths.membersRoot}/${rootPaths.roleRoot}`,
    rolesAdd: `/${rootPaths.membersRoot}/${rootPaths.roleRoot}/new`,
    rolesExports: `/${rootPaths.membersRoot}/${rootPaths.roleRoot}/export`,
    rolesEdit: `/${rootPaths.membersRoot}/${rootPaths.roleRoot}/:id/edit`,
    events: `/${rootPaths.eventsRoot}`,
    eventsLanding: `/${rootPaths.landingRoot}/${rootPaths.eventsRoot}`,
    eventDetail: `/${rootPaths.eventsRoot}/:id/detail`,
    eventTasks: `/${rootPaths.eventsRoot}/:id/tasks`,
    eventDetailLanding: `/${rootPaths.landingRoot}/${rootPaths.eventsRoot}/:id`,
    createEvent: `/${rootPaths.eventsRoot}/new`,
    importEvent: `/${rootPaths.eventsRoot}/importer`,
    importList: `/${rootPaths.eventsRoot}/import`,
    importDetail: `/${rootPaths.eventsRoot}/import/:id/detail`,
    editEvent: `/${rootPaths.eventsRoot}/:id/edit`,
    eventAttendanceTable: `/${rootPaths.eventsRoot}/attendance`,
    eventAttendanceDetail: `/${rootPaths.eventsRoot}/attendance/:id/detail`,
    eventFinance: `/${rootPaths.eventsRoot}/:id/finance`,
    enotice: `/${rootPaths.enoticeRoot}`,
    enoticeCreate: `/${rootPaths.enoticeRoot}/new`,
    enoticeEdit: `/${rootPaths.enoticeRoot}/:id/edit`,
    enoticeDetail: `/${rootPaths.enoticeRoot}/:id/detail`,
    social: `/${rootPaths.socialRoot}`,
    billingList: `/${rootPaths.billingRoot}`,
    billingExports: `/${rootPaths.billingRoot}/export`,
    billingDetail: `/${rootPaths.billingRoot}/:id/detail`,
    konnectWebhook: `/${rootPaths.billingRoot}/billing/konnect/webhook`,
    pricingList: `/${rootPaths.pricingRoot}`,
    organizers: `/${rootPaths.managementRoot}/${rootPaths.organizersRoot}`,
    createOrganizers: `/${rootPaths.managementRoot}/${rootPaths.organizersRoot}/new`,
    editOrganizers: `/${rootPaths.managementRoot}/${rootPaths.organizersRoot}/:id/edit`,
    detailOrganizers: `/${rootPaths.managementRoot}/${rootPaths.organizersRoot}/:id/detail`,
    companies: `/${rootPaths.managementRoot}/${rootPaths.companyRoot}`,
    editCompany: `/${rootPaths.managementRoot}/${rootPaths.companyRoot}/:id/edit`,
    createCompany: `/${rootPaths.managementRoot}/${rootPaths.companyRoot}/new`,
    sponsors: `/${rootPaths.managementRoot}/${rootPaths.sponsorsRoot}`,
    createSponsors: `/${rootPaths.managementRoot}/${rootPaths.sponsorsRoot}/new`,
    editSponsors: `/${rootPaths.managementRoot}/${rootPaths.sponsorsRoot}/:id/edit`,
    detailSponsors: `/${rootPaths.managementRoot}/${rootPaths.sponsorsRoot}/:id/detail`,
    request: `/${rootPaths.managementRoot}/${rootPaths.requestRoot}`,
    requestExports: `/${rootPaths.managementRoot}/${rootPaths.requestRoot}/export`,
    requestDetail: `/${rootPaths.managementRoot}/${rootPaths.requestRoot}/:id/detail`,
    rules: `/${rootPaths.managementRoot}/${rootPaths.rulesRoot}`,
    ruleCreate: `/${rootPaths.managementRoot}/${rootPaths.rulesRoot}/new`,
    ruleEdit: `/${rootPaths.managementRoot}/${rootPaths.rulesRoot}/:id/edit`,
    ruleDetail: `/${rootPaths.managementRoot}/${rootPaths.rulesRoot}/:id/detail`,
    contracts: `/${rootPaths.managementRoot}/${rootPaths.contractRoot}`,
    contractCreate: `/${rootPaths.managementRoot}/${rootPaths.contractRoot}/new`,
    contractEdit: `/${rootPaths.managementRoot}/${rootPaths.contractRoot}/:id/edit`,
    contractDetail: `/${rootPaths.managementRoot}/${rootPaths.contractRoot}/:id/detail`,
    membershipList: `/${rootPaths.managementRoot}/${rootPaths.membershipRoot}`,
    membershipCreate: `/${rootPaths.managementRoot}/${rootPaths.membershipRoot}/new`,
    membershipEdit: `/${rootPaths.managementRoot}/${rootPaths.membershipRoot}/:id/edit`,
    membershipCheckout: `/${rootPaths.managementRoot}/${rootPaths.membershipRoot}/:id/checkout`,
    membershipPayment: `/${rootPaths.managementRoot}/${rootPaths.membershipRoot}/:id/payment`,
    membershipPaymentCancelled: `/${rootPaths.managementRoot}/${rootPaths.membershipRoot}/:id/payment/cancelled`,
    membershipPaymentWebhook: `/${rootPaths.managementRoot}/${rootPaths.membershipRoot}/:id/payment/webhook`,
    currencyList: `/${rootPaths.managementRoot}/${rootPaths.currencyRoot}`,
    currencyCreate: `/${rootPaths.managementRoot}/${rootPaths.currencyRoot}/new`,
    currencyEdit: `/${rootPaths.managementRoot}/${rootPaths.currencyRoot}/:code/edit`,
    blog: `/${rootPaths.managementRoot}/${rootPaths.blogRoot}`,
    blogCreate: `/${rootPaths.managementRoot}/${rootPaths.blogRoot}/new`,
    blogEdit: `/${rootPaths.managementRoot}/${rootPaths.blogRoot}/:id/edit`,
    blogLandingDetail: `/${rootPaths.landingRoot}/${rootPaths.blogRoot}/:id/detail`,
    testimonial: `/${rootPaths.managementRoot}/${rootPaths.testimonialRoot}`,
    testimonialCreate: `/${rootPaths.managementRoot}/${rootPaths.testimonialRoot}/new`,
    testimonialEdit: `/${rootPaths.managementRoot}/${rootPaths.testimonialRoot}/:id/edit`,
    periodic: `/${rootPaths.managementRoot}/${rootPaths.periodicRoot}`,
    periodicCreate: `/${rootPaths.managementRoot}/${rootPaths.periodicRoot}/new`,
    periodicEdit: `/${rootPaths.managementRoot}/${rootPaths.periodicRoot}/:id/edit`,
    newsletter: `/${rootPaths.managementRoot}/${rootPaths.newsletterRoot}`,
    newsletterCreate: `/${rootPaths.managementRoot}/${rootPaths.newsletterRoot}/new`,
    newsletterEdit: `/${rootPaths.managementRoot}/${rootPaths.newsletterRoot}/:id/edit`,
    activityList: `/${rootPaths.activitiesRoot}/${rootPaths.activityRoot}`,
    activityCreate: `/${rootPaths.activitiesRoot}/${rootPaths.activityRoot}/new`,
    activityExports: `/${rootPaths.activitiesRoot}/${rootPaths.activityRoot}/export`,
    activityEdit: `/${rootPaths.activitiesRoot}/${rootPaths.activityRoot}/:id/edit`,
    activityDetail: `/${rootPaths.activitiesRoot}/${rootPaths.activityRoot}/:id/detail`,
    activityMembers: `/${rootPaths.activitiesRoot}/${rootPaths.activityRoot}/:id/members`,
    activitySettings: `/${rootPaths.activitiesRoot}/${rootPaths.activityRoot}/settings`,
    simpleLogin: `/${rootPaths.authRoot}/${rootPaths.authSimpleRoot}/login`,
    simpleRegister: `/${rootPaths.authRoot}/${rootPaths.authSimpleRoot}/register`,
    simpleLogout: `/${rootPaths.authRoot}/${rootPaths.authSimpleRoot}/logout`,
    simpleForgotPassword: `/${rootPaths.authRoot}/${rootPaths.authSimpleRoot}/forgot-password`,
    simpleResetPassword: `/${rootPaths.authRoot}/${rootPaths.authSimpleRoot}/reset-password`,
    simpleConfirmMail: `/${rootPaths.authRoot}/${rootPaths.authSimpleRoot}/confirm-mail`,
    simpleVerifyMail: `/${rootPaths.authRoot}/${rootPaths.authSimpleRoot}/verify-mail`,
    simpleVerify: `/${rootPaths.authRoot}/${rootPaths.authSimpleRoot}/verify`,
    simpleLockScreen: `/${rootPaths.authRoot}/${rootPaths.authSimpleRoot}/lock-screen`,
    cardLogin: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/login`,
    cardRegister: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/register`,
    cardLogout: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/logout`,
    cardForgotPassword: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/forgot-password`,
    cardResetPassword: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/reset-password`,
    cardConfirmMail: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/confirm-mail`,
    cardVerifyMail: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/verify-mail`,
    cardVerify: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/verify`,
    cardLockScreen: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/lock-screen`,
    splitLogin: `/${rootPaths.authRoot}/${rootPaths.authSplitRoot}/login`,
    splitLoginCode: `/${rootPaths.authRoot}/${rootPaths.authSplitRoot}/login/code`,
    splitLoginConfirmCode: `/${rootPaths.authRoot}/${rootPaths.authSplitRoot}/login/code/confirm`,
    splitReauthenticate: `/${rootPaths.authRoot}/${rootPaths.authSplitRoot}/reauthenticate`,
    splitRecoveryCodeLogin: `/${rootPaths.authRoot}/${rootPaths.authSplitRoot}/login/recovery-codes`,
    splitWebauthnLogin: `/${rootPaths.authRoot}/${rootPaths.authSplitRoot}/login/webauthn`,
    splitTOTPLogin: `/${rootPaths.authRoot}/${rootPaths.authSplitRoot}/login/totp`,
    splitRegister: `/${rootPaths.authRoot}/${rootPaths.authSplitRoot}/register`,
    splitLogout: `/${rootPaths.authRoot}/${rootPaths.authSplitRoot}/logout`,
    splitForgotPassword: `/${rootPaths.authRoot}/${rootPaths.authSplitRoot}/forgot-password`,
    splitResetPassword: `/${rootPaths.authRoot}/${rootPaths.authSplitRoot}/reset-password`,
    splitConfirmMail: `/${rootPaths.authRoot}/${rootPaths.authSplitRoot}/confirm-mail`,
    splitVerifyMail: `/${rootPaths.authRoot}/${rootPaths.authSplitRoot}/verify-mail`,
    splitVerify: `/${rootPaths.authRoot}/${rootPaths.authSplitRoot}/verify`,
    splitLockScreen: `/${rootPaths.authRoot}/${rootPaths.authSplitRoot}/lock-screen`,
    authWizard: `/${rootPaths.authRoot}/wizard`,
    analytics: `/analytics`,
    dashboardFinance: `/finance`,
    dashboardSupport: `/support-desk`,
    userProfile: `/${rootPaths.userRoot}/profile`,
    userSettings: `/${rootPaths.userRoot}/settings`,
    userConnections: `/${rootPaths.userRoot}/connections`,
    userFollowers: `/${rootPaths.userRoot}/followers`,
    userEmails: `/${rootPaths.userRoot}/email`,
    userPhoneNumbers: `/${rootPaths.userRoot}/phone`,
    userEmailVerification: `/${rootPaths.userRoot}/email/verify`,
    userExperiences: `/${rootPaths.userRoot}/experience`,
    userEducations: `/${rootPaths.userRoot}/education`,
    userMFA: `/${rootPaths.userRoot}/mfa`,
    reports: `/${rootPaths.appsRoot}/${rootPaths.reportsRoot}`,
    reportsCreate: `/${rootPaths.appsRoot}/${rootPaths.reportsRoot}/new`,
    reportsEdit: `/${rootPaths.appsRoot}/${rootPaths.reportsRoot}/:id/edit`,
    reportsDetail: `/${rootPaths.appsRoot}/${rootPaths.reportsRoot}/:id/detail`,
    transactions: `/${rootPaths.appsRoot}/${rootPaths.transactionsRoot}`,
    transactionDetail: `/${rootPaths.appsRoot}/${rootPaths.transactionsRoot}/:id/detail`,
    transactionKonnectWebhook: `/${rootPaths.appsRoot}/${rootPaths.transactionsRoot}/konnect/webhook`,
    transactionPayment: `/${rootPaths.appsRoot}/${rootPaths.transactionsRoot}/:id/payment`,
    transactionPaymentCancelled: `/${rootPaths.appsRoot}/${rootPaths.transactionsRoot}/:id/payment/cancelled`,
    transactionPaymentWebhook: `/${rootPaths.appsRoot}/${rootPaths.transactionsRoot}/:id/payment/webhook`,
    callbackCalendly: `/${rootPaths.authRoot}/calendly/connect/callback/`,
    callbackGoogle: `/${rootPaths.authRoot}/google/:type/callback/`,
    callbackZoom: `/${rootPaths.authRoot}/zoom/:type/callback/`,
    timeline: `/${rootPaths.timelineRoot}`,
    timelineCreate: `/${rootPaths.timelineRoot}/new`,
    tutorial: `/${rootPaths.tutorialRoot}`,
    tutorialCreate: `/${rootPaths.tutorialRoot}/new`,
    tutorialEdit: `/${rootPaths.tutorialRoot}/:id/edit`,
    tutorialDetail: `/${rootPaths.tutorialRoot}/:id/detail`,
    bug: `/${rootPaths.bugRoot}`,
    bugCreate: `/${rootPaths.bugRoot}/new`,
    bugDetail: `/${rootPaths.bugRoot}/:id/detail`,
    provider: `/${rootPaths.providerRoot}`,
    providerCreate: `/${rootPaths.providerRoot}/new`,
    providerEdit: `/${rootPaths.providerRoot}/:id/edit`,
    providerDetail: `/${rootPaths.providerRoot}/:id/detail`,
    supportTable: `/${rootPaths.supportRoot}/table-view`,
    supportCard: `/${rootPaths.supportRoot}/card-view`,
    createSupportTicket: `/${rootPaths.supportRoot}/ticket/new`,
    detailSupportTicket: `/${rootPaths.supportRoot}/ticket/:id/detail`,
    collaborationCenter: `/${rootPaths.collaborationRoot}`,
    collaborationCreate: `/${rootPaths.collaborationRoot}/new`,
    collaborationEdit: `/${rootPaths.collaborationRoot}/:id/edit`,
    collaborationDetail: `/${rootPaths.collaborationRoot}/:id/detail`,
    venueList: `/${rootPaths.venueRoot}`,
    venueCreate: `/${rootPaths.venueRoot}/new`,
    venueDetail: `/${rootPaths.venueRoot}/:id/detail`,
    venueEdit: `/${rootPaths.venueRoot}/:id/edit`,
    siteList: `/${rootPaths.siteRoot}`,
    siteCreate: `/${rootPaths.siteRoot}/new`,
    siteDetail: `/${rootPaths.siteRoot}/:id/detail`,
    siteEdit: `/${rootPaths.siteRoot}/:id/edit`,
    schoolList: `/${rootPaths.schoolRoot}`,
    schoolCreate: `/${rootPaths.schoolRoot}/new`,
    schoolChange: `/${rootPaths.schoolRoot}/:id/edit`,
};
