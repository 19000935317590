import {withPermission} from "../../../helpers/utils";
import {withTranslation} from "react-i18next";
import React, {Fragment, useEffect, useState} from "react";
import {api} from "../../../utils/api";
import IconButton from "../../../components/common/IconButton";
import {useAppContext} from "../../../providers/AppProvider";
import {
    create,
    parseCreationOptionsFromJSON
} from '@github/webauthn-json/browser-ponyfill'
import {Form} from "react-bootstrap";
import CSRFToken from "../../../helpers/CSRFToken";

const RegisterSecurityKey = ({t, i18n}) => {
    const [passwordless, setPasswordless] = useState(false)
    const [name, setName] = useState('')
    const [challenge, setChallenge] = useState(null);

    const {
        config: {isAuthenticated},
        setConfig
    } = useAppContext()

    const WebAuthnCreationOptions = async () => {
        await api.get(`/_allauth/browser/v1/account/authenticators/webauthn${passwordless ? '?passwordless' : ''}`)
            .then(res => {
                setChallenge(res?.data?.data?.creation_options)
            })
            .catch(error => {
                if (error?.response?.status === 401) setConfig("isAuthenticated", !isAuthenticated)
            })
    }

    useEffect(() => {
        WebAuthnCreationOptions()
        // eslint-disable-next-line
    }, []);

    const handleRegister = async () => {
        if (!challenge) {
            alert("Challenge not ready. Try again.");
            return;
        }

        try {
            const options = parseCreationOptionsFromJSON(challenge)
            const credential = await create(options)
            await api.post('/_allauth/browser/v1/account/authenticators/webauthn', {name, credential})
                .then(res => {

                })
        } catch (error) {
            console.error("Registration failed:", error);
            alert("Failed to register security key. Please try again.");
        }
    };

    return (
        <Fragment>
            <Form>
                <CSRFToken/>
                <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type={"text"}
                        value={name}
                        onChange={e => setName(e.target.value)}
                        placeholder={"Name"}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Switch
                        label={"Passwordless"}
                        onChange={(e) => setPasswordless(e.target.checked)}
                        checked={passwordless}
                    />
                </Form.Group>
            </Form>
            <IconButton
                icon={"plus"}
                size={"sm"}
                variant={"outline-primary"}
                onClick={handleRegister}
                disabled={!challenge}
            >
                <span className={"d-none d-lg-inline-block"}>Add a key</span>
            </IconButton>
        </Fragment>
    );
}

export default withPermission(withTranslation(["common"])(RegisterSecurityKey), "two_factor_webauthn.view_webauthndevice")
